import _ from 'lodash'
import Papa from 'papaparse'

const flatten = (data) => {
  const result = []

  const flattenObject = (obj, parentKey = '') => {
    return _.reduce(
      obj,
      (acc, value, key) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key
        if (_.isObject(value) && !_.isArray(value)) {
          _.assign(acc, flattenObject(value, newKey))
        } else {
          acc[newKey] = value
        }
        return acc
      },
      {}
    )
  }

  _.forEach(data, (row) => {
    result.push(flattenObject(row))
  })

  return result
}

const csvTableColumns = (table) => {
  return _.sortBy(_.keys(table[0]))
}

const csvFormatter = (data, columns) => {
  const filteredData = _.map(data, (row) => {
    return _.pick(row, columns)
  })
  const flattenedData = flatten(filteredData)
  return Papa.unparse(flattenedData, {
    quotes: true
  })
}

const csvExporter = (csvAsString, filename = 'table.csv') => {
  const byteOrderMark = '\ufeff'

  const blob = new Blob([byteOrderMark, csvAsString], {
    type: 'text/csv;charset=utf-8;'
  })

  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export { csvFormatter, csvExporter, csvTableColumns }
