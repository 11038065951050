<template>
  <app-form-view
    multipart
    app="contact"
    model="personaldocument"
    api-url="contact/personal-doc/"
    :title="$t('menu.TaxRegistrationCertificate')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveFields"
    :prepare-data="prepareData"
    v-model="formData"
    @input="loadDocuments"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" md="6">
          <v-row
            ><v-col cols="12" lg="12">
              <app-input
                name="name"
                :label="$t('fields.documentName')"
                :view="view"
                v-model="formData.document_name"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_name"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="contact"
                type="chips-server"
                rules="required"
                :label="$t('fields.contact')"
                :view="view"
                :binds="{
                  apiUrl: 'contact/contact/?contact_type=company',
                  chip: {
                    color: 'secondary',
                    icon: 'mdi-account-circle-outline',
                    value: 'name',
                    router: contactRouter
                  }
                }"
                v-model="formData.contact_ids"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveContact"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_contact_ids"
              />
            </v-col>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="type"
                rules="required"
                :label="$t('fields.type')"
                :view="view"
                value="company"
                disabled
              >
              </app-input>

              <app-input
                v-if="isSentToApprove"
                name="approveType"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_type"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="documentTemplate"
                rules="required"
                :label="$t('fields.documentTemplate')"
                :view="view"
                value="ภ.พ.20"
                disabled
              />

              <app-input
                v-if="isSentToApprove"
                name="approveDocumentTemplate"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_document_template_id"
              />
            </v-col>
            <template v-if="formData.document_template_id">
              <v-col
                v-for="field in documentTemplateFields"
                cols="12"
                md="6"
                class="app-input--approve-wrapper"
                :key="field.field"
              >
                <app-input
                  :rules="{
                    required: true,
                    numeric: field.field_type === 'number'
                  }"
                  :name="field.field"
                  :type="field.field_type"
                  :label="field.name"
                  :items="fieldSelectionItems(field)"
                  :view="view"
                  v-model="formData.fields[field.field]"
                />

                <app-input
                  v-if="isSentToApprove"
                  type="switch"
                  class="approve"
                  :name="`approve-${field.name}`"
                  :label="$t('fields.approve')"
                  v-model="formData[`approve_${field.field}`]"
                />
              </v-col>
            </template>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="app-input--approve-wrapper">
              <app-input
                name="customer_name"
                type="select-server"
                rules="required"
                :label="$t('fields.customerCompany')"
                :view="view"
                :binds="{
                  apiUrl: 'customer-company/customer_company/?active=true'
                }"
                v-model="formData.customer_company_id"
              />
              <app-input
                v-if="isSentToApprove"
                name="approveCustomerCompany"
                type="switch"
                class="approve"
                :label="$t('fields.approve')"
                v-model="formData.approve_customer_company"
              />
            </v-col>
          </v-row>
          <v-row v-if="!view">
            <v-col cols="12" lg="6">
              <app-input
                name="documents"
                type="file"
                rules="required"
                :label="$t('fields.document')"
                :view="view"
                :binds="{ multiple: true, chips: true }"
                v-model="formData.documents"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <app-preview-file
            :file-ready="documentReady || mode === 'create'"
            :files="formData.documents || []"
          />
        </v-col>
      </v-row>
      <slot :view="view" />
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppPreviewFile from '@components/AppPreviewFile'
import { getFile } from '@utils/function'

export default {
  name: 'TaxRegistrationCertificate-form',
  components: {
    AppInput,
    AppFormView,
    AppPreviewFile
  },
  props: {
    hideEdit: {
      type: Boolean,
      default: true
    },
    hideDelete: {
      type: Boolean,
      default: false
    },
    binds: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    headers() {
      return [
        { text: this.$t('fields.no'), value: 'seq' },
        { text: this.$t('fields.prefix_id'), value: 'prefix_id.name' },
        { text: this.$t('fields.name'), value: 'first_name' },
        { text: this.$t('fields.lastname'), value: 'last_name' },
        { text: this.$t('fields.cardType'), value: 'card_type' },
        { text: this.$t('fields.id'), value: 'id_card' },
        { text: this.$t('fields.age'), value: 'age' },
        { text: this.$t('fields.phoneNumber'), value: 'phone_number' },
        { text: this.$t('fields.occupation'), value: 'occupation' },
        { text: this.$t('fields.address'), value: 'address' },
        { text: this.$t('fields.numberShares'), value: 'total_stock' },
        { text: this.$t('label.action'), value: 'actions', sortable: false }
      ]
    },
    documentTemplateFields() {
      const fields = [...this.formData.document_template_id.fields]
      fields.sort((a, b) => {
        if (a.code < b.code) return -1
        if (a.code > b.code) return 1
        return 0
      })
      return fields
    },
    approveFields() {
      return this.formData.document_template_id
        ? [...this.formData.document_template_id.fields]
            .map((field) => 'approve_' + field.field)
            .concat([
              'approve_contact_ids',
              'approve_type',
              'approve_document_template_id'
            ])
        : []
    }
  },

  data() {
    return {
      documentReady: false,
      formData: {
        state: 'draft',
        fields: {}
      },
      search: '',
      items: [],
      selected: [],
      savedItem: {},
      serverItemsLength: 0,
      loading: false,
      editedItem: {
        personal_id: null,
        card_type: null,
        phone_number: null,
        occupation: null
      },
      dialog: false,
      dialogDelete: false,
      apiMethod: 'post',
      editId: null
    }
  },
  watch: {
    'formData.document_name': function (value) {
      if (this.$route.name === 'TaxRegistrationCertificateEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'TaxRegistrationCertificateEdit',
          text: value ?? 'N/A',
          to: {
            name: 'TaxRegistrationCertificateEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    contactRouter(item) {
      return {
        name: 'contactEdit',
        params: { id: item.id }
      }
    },
    async loadDocuments() {
      this.formData.documents = await Promise.all(
        this.formData.documents.map((document) => getFile(document))
      )
      this.documentReady = true
    },
    fieldSelectionItems(field) {
      if (field.field_type !== 'select') return []
      return field.selection_members.map((value) => ({
        ...value,
        value: value.label
      }))
    },
    prepareData(data) {
      data.contact_ids = data.contact_ids.map((value) => value.id)
      data.document_template_id = data.document_template_id.id
      data.customer_company_id = data.customer_company_id.id
      data.type = 'company'
      return data
    },
    onEdit(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialog = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `special_doc/contributor-doc/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    getDocData() {
      this.$api({
        method: 'get',
        url: `doc-template/document/?search=ภ.พ.20`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.formData.document_template_id = data.results[0]
        this.loading = false
      })
    }
  },
  created() {
    this.getDocData()
    if (this.$route.name === 'TaxRegistrationCertificateCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'TaxRegistrationCertificateCreate'
      })
    }
  }
}
</script>
