import request from '@utils/request'

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

const getJwtPayload = (token) => {
  const payload = token.split('.')[1]
  if (payload) {
    return JSON.parse(atob(payload))
  }
  return false
}

const isFile = (value) =>
  typeof value === 'object' && value?.name && value?.size && value?.type

const objFormData = (object) => {
  const prepareValue = (acc, key, value) => {
    if (typeof value === 'object' && !isFile(value)) {
      value = JSON.stringify(value)
    }
    acc.append(key, value)
  }
  return Object.keys(object).reduce((acc, key) => {
    let value = object[key]
    if (![null, undefined].includes(value)) {
      if (Array.isArray(value)) {
        value.map((child) => {
          prepareValue(acc, key, child)
        })
      } else {
        prepareValue(acc, key, value)
      }
    }
    return acc
  }, new FormData())
}

const getFile = (path, fileName, fileType) =>
  new Promise((resolve) => {
    if (path[0] === '/') {
      path = path.substring(1)
    }
    request({
      method: 'get',
      url: `${process.env.VUE_APP_BASE_API}${path}`,
      responseType: 'arraybuffer',
      hideSuccessAlert: true
    })
      .then(({ data, headers }) => {
        const buffer = Buffer.from(data)
        fileName = fileName || path.substr(path.lastIndexOf('/') + 1)
        fileType = fileType || headers['content-type']
        const file = new File([buffer], fileName, {
          type: fileType,
          lastModified: new Date()
        })
        resolve(file)
      })
      .catch(() => {
        resolve(null)
      })
  })

const blobToBase64 = (blob) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })

export { sleep, getJwtPayload, isFile, objFormData, getFile, blobToBase64 }
