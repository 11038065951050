import breadcrumbs from '@/router/breadcrumbs'

export default {
  addBreadcrumb(state, breadcrumb) {
    // Find root level route that matches the current breadcrumb's path
    const rootRoute = findRootRoute(breadcrumbs.routes, breadcrumb.name)
    if (!rootRoute) {
      state.breadcrumbs = [rootRoute]
      return
    }

    // If it's a root level route itself
    if (breadcrumb.name === rootRoute.name) {
      state.breadcrumbs = [rootRoute]
      return
    }

    const fullPath = findBreadcrumbPath(
      breadcrumbs.routes,
      breadcrumb.name,
      rootRoute.name
    )

    if (fullPath.length === 0) {
      state.breadcrumbs = []
      return
    }
    if (state.breadcrumbs[state.breadcrumbs.length - 1]?.disabled) {
      state.breadcrumbs[state.breadcrumbs.length - 1].disabled = false
    }

    // Check for duplicate params.id and remove subsequent items
    if (breadcrumb.to?.params?.id) {
      const duplicateIndex = state.breadcrumbs.findIndex(
        (crumb) => crumb.to?.params?.id === breadcrumb.to.params.id
      )
      if (duplicateIndex !== -1) {
        state.breadcrumbs = state.breadcrumbs.slice(0, duplicateIndex + 1)
        return
      }
    }

    if (state.breadcrumbs[state.breadcrumbs.length - 1]?.is_removed) {
      state.breadcrumbs.pop()
    }

    let lastBreadcrumb = state.breadcrumbs[state.breadcrumbs.length - 1]
    // If the last breadcrumb is stacked, add the new breadcrumb to its children
    if (state.breadcrumbs.length > 0 && lastBreadcrumb?.stacked) {
      const crumbs = lastBreadcrumb.children.find(
        (crumb) => crumb.name === breadcrumb.name
      )
      if (crumbs) {
        if (crumbs.name == lastBreadcrumb.name) {
          state.breadcrumbs.push({
            ...lastBreadcrumb,
            text: breadcrumb.text || lastBreadcrumb.text,
            to: breadcrumb.to || lastBreadcrumb.to,
            disabled: true
          })
          return
        }
        state.breadcrumbs.push({
          ...crumbs,
          text: breadcrumb.text || crumbs.text,
          to: breadcrumb.to || crumbs.to,
          disabled: true
        })
        return
      }
    }

    // Create a new array with mapped breadcrumbs instead of modifying the original
    const mappedPath = fullPath
      .map((crumb, i) => {
        if (crumb.name === breadcrumb.name) {
          return {
            ...crumb,
            text: breadcrumb.text || crumb.text,
            to: breadcrumb.to || crumb.to
          }
        } else if (state.breadcrumbs.length > i) {
          return state.breadcrumbs[i]
        } else if (crumb.text && crumb.text.length > 0) {
          return crumb
        }
        return null
      })
      .filter(Boolean)

    mappedPath[mappedPath.length - 1].disabled = true
    state.breadcrumbs = mappedPath
  },
  clearBreadcrumb(state) {
    state.breadcrumbs = []
  }
}

// Helper function to find the root route for a given route name
function findRootRoute(routes, targetName) {
  // First check if the target is a root route
  const rootRoute = routes.find((route) => route.name === targetName)
  if (rootRoute) return rootRoute

  // Then check if the target belongs to any root route's hierarchy
  return routes.find((route) => {
    return isRouteDescendant(route, targetName)
  })
}

// Helper function to check if a route name belongs to a route's hierarchy
function isRouteDescendant(route, targetName) {
  if (route.name === targetName) return true

  if (route.children) {
    return route.children.some((child) => {
      return isRouteDescendant(child, targetName)
    })
  }

  return false
}

function findBreadcrumbPath(items, targetName, rootName = null, path = []) {
  // If we have a root name, start search from there
  if (rootName) {
    const rootItem = items.find((item) => item.name === rootName)
    if (rootItem) {
      // If target is the root itself
      if (targetName === rootName) {
        return [rootItem]
      }
      // Search in root's children
      if (rootItem.children) {
        const foundInRoot = findBreadcrumbPath(
          rootItem.children,
          targetName,
          null,
          [rootItem]
        )
        if (foundInRoot.length) {
          return foundInRoot
        }
      }
      return []
    }
  }

  // Normal search if no root specified or root not found
  for (const item of items) {
    if (item.name === targetName) {
      return [...path, item]
    }

    if (item.children) {
      const foundPath = findBreadcrumbPath(item.children, targetName, null, [
        ...path,
        item
      ])
      if (foundPath.length) {
        return foundPath
      }
    }
  }

  return []
}
