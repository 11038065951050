<template>
  <app-menu-form
    :headers="taskPropertyAndAgreementHeader"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :server-items="taskPropertyAndAgreementItems"
    :client-items="taskPropertyAndAgreementItems"
    :loading="loading"
    :server-items-length="taskPropertyAndAgreementItemsLength"
    :events="eventHandlers"
  >
    <template v-slot:item.group_link="{ item }">
      <template v-if="item.property_and_agreement">
        <router-link
          :to="{
            name:
              item.property_and_agreement === 'liability'
                ? 'liabilityEdit'
                : item.property_and_agreement === 'asset'
                ? 'assetEdit'
                : item.property_and_agreement === 'expense'
                ? 'expenseEdit'
                : item.property_and_agreement === 'revenue'
                ? 'revenueEdit'
                : item.property_and_agreement === 'human_asset'
                ? 'humanAssetEdit'
                : item.property_and_agreement === 'lawsuit'
                ? 'lawsuitEdit'
                : 'equityEdit',
            params: { id: item.group_id }
          }"
          target="_blank"
        >
          <v-icon color="primary">mdi-link</v-icon>
        </router-link>
      </template>
    </template>

    <template v-slot:top>
      <app-menu-form-top
        :mode="mode"
        :title="$t('menu.propertyAndAgreement')"
        :dialog="dialogTaskPropertyAndAgreement"
        :selected-length="taskPropertyAndAgreementSelected.length"
        :onDelete="onDeleteTaskPropertyAndAgreement"
        @show-dialog="dialogTaskPropertyAndAgreement = $event"
      >
        <template v-slot:dialogForm>
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      v-model="
                        editedPropertyAndAgreementItem.property_and_agreement
                      "
                      @input="getGroupName"
                      name="porpery_and_agreement"
                      type="select"
                      :label="$t('fields.propertyAndAgreement')"
                      :view="view"
                      :items="[
                        { label: $t('label.asset'), value: 'asset' },
                        {
                          label: $t('label.liability'),
                          value: 'liability'
                        },
                        { label: $t('label.equity'), value: 'equity' },
                        {
                          label: $t('label.revenue'),
                          value: 'revenue'
                        },
                        {
                          label: $t('label.expense'),
                          value: 'expense'
                        },
                        {
                          label: $t('label.human_asset'),
                          value: 'human_asset'
                        },
                        {
                          label: $t('label.lawsuit'),
                          value: 'lawsuit'
                        }
                      ]"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      name="group_name"
                      type="select-server"
                      :label="$t('fields.groupName')"
                      :view="view"
                      :binds="{ apiUrl: groupNameUrl }"
                      v-model="editedPropertyAndAgreementItem.group_name"
                      :disabled="
                        !Boolean(
                          editedPropertyAndAgreementItem.property_and_agreement
                        )
                      "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      name="contact_branch_id"
                      type="select-server"
                      :label="$t('fields.branchName')"
                      :view="view"
                      :binds="{
                        apiUrl: `contact/contact-branch/?contact_id=${contactId.id}`
                      }"
                      v-model="editedPropertyAndAgreementItem.contact_branch_id"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      name="business_unit_id"
                      type="select-server"
                      :label="$t('fields.businessUnit')"
                      :view="view"
                      :binds="{
                        apiUrl: `contact/business-unit/?active=true&contact_id=${contactId.id}`
                      }"
                      v-model="editedPropertyAndAgreementItem.business_unit_id"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <app-input
                      name="note"
                      type="text"
                      :label="$t('fields.note')"
                      :view="view"
                      v-model="editedPropertyAndAgreementItem.note"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeTaskPropertyAndAgreement"
                >Cancel</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                @click="saveTaskPropertyAndAgreement"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </app-menu-form-top>
    </template>
  </app-menu-form>
</template>

<script>
import AppInput from '@components/AppInput.vue'
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'

export default {
  name: 'app-property-agreement',
  components: {
    AppInput,
    AppMenuForm,
    AppMenuFormTop
  },
  props: {
    mode: {
      type: String,
      required: true
    },
    view: {
      type: Boolean
    },
    contactId: {
      type: Object
    }
  },
  data() {
    return {
      dialogTaskPropertyAndAgreement: false,
      editedPropertyAndAgreementItem: {},
      loading: false,
      groupNameUrl: null,
      taskPropertyAndAgreementItems: [],
      taskPropertyAndAgreementClientItems: [],
      taskPropertyAndAgreementSelected: [],
      taskPropertyAndAgreementItemsLength: 0,
      apiMethod: 'post'
    }
  },
  computed: {
    taskPropertyAndAgreementHeader() {
      return [
        {
          text: this.$t('fields.propertyAndAgreement'),
          value: 'property_and_agreement'
        },
        {
          text: this.$t('fields.groupName'),
          value: 'group_name'
        },
        {
          text: this.$t('fields.link'),
          value: 'group_link',
          link: true
        },
        {
          text: this.$t('fields.branchCode'),
          value: 'contact_branch_id.branch_code'
        },
        {
          text: this.$t('fields.branchName'),
          value: 'contact_branch_id.name'
        },
        {
          text: this.$t('fields.businessUnit'),
          value: 'business_unit_id.name'
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    eventHandlers() {
      return {
        server: this.getTaskPropertyAndAgreementItems,
        client: this.getTaskPropertyAndAgreementClientItems,
        delete: this.onDeleteTaskPropertyAndAgreementItem,
        edit: this.onEditTaskPropertyAndAgreement
      }
    }
  },
  methods: {
    getTaskPropertyAndAgreementItems(options = null) {
      this.loading = true

      if (options) {
        const offset = (options.page - 1) * options.itemsPerPage
        var ordering = options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')

        if (options.sortBy.length == 0) {
          ordering = 'property_and_agreement'
        }

        this.params = {
          limit: options.itemsPerPage,
          offset: offset,
          search: options.search,
          ordering: ordering,
          ...options.multiSearch,
          task_id: this.$route.params.id
        }
      } else {
        this.params = {
          task_id: this.$route.params.id,
          ordering: 'property_and_agreement'
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: 'task/task-property/',
          params: this.params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.taskPropertyAndAgreementItemsLength = data.count
          this.taskPropertyAndAgreementItems = data.results
          this.taskPropertyAndAgreementClientItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getTaskPropertyAndAgreementClientItems(multiSearch) {
      this.taskPropertyAndAgreementClientItems =
        this.taskPropertyAndAgreementItems.filter((item) => {
          return Object.entries(multiSearch).every(([key, value]) => {
            if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
              return item[key.slice(0, key.lastIndexOf('.'))][
                key.slice(key.lastIndexOf('.') + 1)
              ]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            } else {
              return item[key]
                .toString()
                .toUpperCase()
                .includes(value.toString().toUpperCase())
            }
          })
        })
    },
    onDeleteTaskPropertyAndAgreementItem(item) {
      this.onDeleteTaskPropertyAndAgreement(item)
    },
    onDeleteTaskPropertyAndAgreement(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-property/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getTaskPropertyAndAgreementItems()
        })
      })
    },
    onEditTaskPropertyAndAgreement(item) {
      this.dialogTaskPropertyAndAgreement = true
      // this.editedPropertyAndAgreementItem = Object.assign({
      //   property_and_agreement: item.property_and_agreement,
      //   contact_branch_id: item.contact_branch_id,
      //   group_name: item.group_name,
      //   business_unit_id: item.business_unit_id,
      //   note: item.note
      // })
      this.editedPropertyAndAgreementItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
    },
    getGroupName() {
      var url = ''
      var link = ''
      if (
        this.editedPropertyAndAgreementItem.property_and_agreement !== null &&
        this.editedPropertyAndAgreementItem.property_and_agreement !== undefined
      ) {
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement === 'asset'
        ) {
          url = 'asset/asset/'
          link = 'audit/property-and-agreement/asset/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'liability'
        ) {
          url = 'liability/liability'
          link = 'audit/property-and-agreement/liability/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'equity'
        ) {
          url = 'equity/equity'
          link = 'audit/property-and-agreement/equity/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'revenue'
        ) {
          url = 'revenue/revenue'
          link = 'audit/property-and-agreement/revenue/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'expense'
        ) {
          url = 'expense/expense'
          link = 'audit/property-and-agreement/expense/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'human_asset'
        ) {
          url = 'human-asset/human-asset'
          link = 'audit/property-and-agreement/human_asset/record/'
        }
        if (
          this.editedPropertyAndAgreementItem.property_and_agreement ===
          'lawsuit'
        ) {
          url = 'lawsuit/lawsuit'
          link = 'audit/property-and-agreement/lawsuit/record/'
        }
      }
      this.groupNameUrl = url
      this.editedPropertyAndAgreementItem.group_link = link
    },
    closeTaskPropertyAndAgreement() {
      this.dialogTaskPropertyAndAgreement = false
      this.editedPropertyAndAgreementItem = {
        property_and_agreement: '',
        group_name: '',
        contact_branch_id: '',
        business_unit_id: '',
        note: ''
      }
    },
    async saveTaskPropertyAndAgreement() {
      this.loading = true
      this.editedPropertyAndAgreementItem.audit_plan_header_id =
        this.$route.params.audit_plan_id
      this.editedPropertyAndAgreementItem.task_id = this.$route.params.id

      if (this.editedPropertyAndAgreementItem.group_id) {
        this.editedPropertyAndAgreementItem.group_id =
          this.editedPropertyAndAgreementItem.group_name.id
      }

      if (this.editedPropertyAndAgreementItem.group_name) {
        this.editedPropertyAndAgreementItem.group_name =
          this.editedPropertyAndAgreementItem.group_name.name
      }

      if (this.editedPropertyAndAgreementItem.contact_branch_id) {
        this.editedPropertyAndAgreementItem.contact_branch_id =
          this.editedPropertyAndAgreementItem.contact_branch_id.id
      }

      if (this.editedPropertyAndAgreementItem.business_unit_id) {
        this.editedPropertyAndAgreementItem.business_unit_id =
          this.editedPropertyAndAgreementItem.business_unit_id.id
      }

      if (
        this.editedPropertyAndAgreementItem.group_link &&
        this.editedPropertyAndAgreementItem.group_id
      ) {
        this.editedPropertyAndAgreementItem.group_link =
          this.editedPropertyAndAgreementItem.group_link +
          this.editedPropertyAndAgreementItem.group_id
      }

      const data = this.editedPropertyAndAgreementItem

      await this.$api({
        method: this.apiMethod,
        url: `task/task-property/${this.editId ? this.editId + '/' : ''}`,
        data
      })

      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.closeTaskPropertyAndAgreement()
      this.getTaskPropertyAndAgreementItems()
    }
  }
}
</script>
