<template>
  <app-form-view
    app="audit"
    model="auditheader"
    api-url="audit/audit-header/"
    :title="$t('menu.audit')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    :prepare-data="prepareData"
    v-model="formData"
    :is-closed="formData.is_closed"
    :show-close-open-audit="true"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditPeriod"
            rules="required"
            :label="$t('fields.auditPeriod')"
            :view="view"
            v-model="formData.audit_period"
            type="select"
            :items="[
              {
                label: 'Q1',
                value: 'q1'
              },
              {
                label: 'Q2',
                value: 'q2'
              },
              {
                label: 'Q3',
                value: 'q3'
              },
              {
                label: 'Q4',
                value: 'q4'
              },
              { label: 'Year End', value: 'year_end' },
              {
                label: 'Customs',
                value: 'customs'
              }
            ]"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_period"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditYear"
            rules="required"
            type="year-selection"
            :label="$t('fields.auditYear')"
            :view="view"
            v-model="formData.audit_year"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditYear"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_year"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="chartOfAccountHeaderID"
            rules="required"
            type="select-server"
            :label="$t('fields.chartOfAccountName')"
            :view="view"
            :binds="{
              apiUrl: 'chart-of-account/chart-of-account-header/?active=true'
            }"
            v-model="formData.chart_of_account_header_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveChartOfAccountHeaderID"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_chart_of_account_header_id"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="contactID"
            rules="required"
            type="select-server"
            :label="$t('fields.contactName')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.contact_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveContactID"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact_id"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="businessIndustry"
            type="select-server"
            rules="required"
            :label="$t('fields.businessIndustry')"
            :view="view"
            :binds="{
              apiUrl:
                'doc-template/industry/?active=true' +
                '&contact_id=' +
                formData.contact_id?.id
            }"
            v-model="formData.industry_id"
            @input="getBusinessCateItem"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveBusinessIndustry"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_business_industry"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="businessCategory"
            type="select-server"
            rules="required"
            :label="$t('fields.businessCategory')"
            :view="view"
            :binds="{
              apiUrl: categoryUrl
            }"
            v-model="formData.business_cate_id"
            @input="getBusinessTypeItem"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveBusinessCategory"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_business_category"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="businessType"
            type="select-server"
            rules="required"
            :label="$t('fields.businessType')"
            :view="view"
            :binds="{
              apiUrl: buztypeUrl
            }"
            v-model="formData.business_type_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveBusinessType"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_business_type"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Invoice"
            :label="$t('fields.invoice')"
            :view="view"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveInvoice"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_invoice"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="invoiceItem"
            :label="$t('fields.invoiceItem')"
            :view="view"
            v-model="formData.approve_invoice_item"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveInvoiceItem"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_invoice_item"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditPlan"
            rules="required"
            type="select-server"
            :label="$t('fields.auditPlan')"
            :view="view"
            :binds="{
              apiUrl: 'audit/audit-plan-header/'
            }"
            v-model="formData.audit_plan_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditPlan"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_plan_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="preparedBy"
            type="select-server"
            :label="$t('fields.preparedBy')"
            :view="view"
            :binds="{
              apiUrl: `contact/contact/?active=true&audit_header_id=${auditHeaderId}`
            }"
            v-model="formData.prepare_by"
          />

          <app-input
            v-if="isSentToApprove"
            name="approvePreparedBy"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_prepare_by"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="prepareDate"
            type="datepicker"
            :label="$t('fields.prepareDate')"
            :view="view"
            v-model="formData.prepare_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePrepareDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_prepare_date"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewedBy"
            type="select-server"
            :label="$t('fields.reviewedBy')"
            :view="view"
            :binds="{
              apiUrl: `contact/contact/?active=true&audit_header_id=${auditHeaderId}`
            }"
            v-model="formData.review_by"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReviewedBy"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_review_by"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewDate"
            type="datepicker"
            :label="$t('fields.reviewDate')"
            :view="view"
            v-model="formData.review_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReviewDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_review_date"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="startDate"
            rules="required"
            type="datepicker"
            :label="$t('fields.startDate')"
            :view="view"
            v-model="formData.start_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveStartDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_start_date"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="endDate"
            rules="required"
            type="datepicker"
            :label="$t('fields.endDate')"
            :view="view"
            v-model="formData.end_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveEndDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_end_date"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodBeginning"
            type="datepicker"
            :label="$t('fields.accountingPeriodBeginning')"
            :view="view"
            v-model="formData.accounting_period_beginning"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodBeginning"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_beginning"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodEnding"
            type="datepicker"
            :label="$t('fields.accountingPeriodEnding')"
            :view="view"
            v-model="formData.accounting_period_ending"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodEnding"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_ending"
          />
        </v-col>
      </v-row>

      <v-expansion-panels multiple>
        <!-- Audit Panel -->
        <app-expansion-panel :headerText="$t('menu.audit')">
          <v-expansion-panels multiple>
            <!-- Audit Program Panel -->
            <app-expansion-panel :headerText="$t('menu.auditPlan')">
              <app-audit-plan hide-edit hide-delete :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Pre Audit Panel -->
            <app-expansion-panel :headerText="$t('menu.preAudit')">
              <app-audit
                :title="$t('menu.preAudit')"
                :taskItemHeader="taskItemHeader"
                :auditTasks="preAuditTasks"
                :loading="loading"
                :auditTasksLength="preAuditTasksLength"
                :mode="mode"
                :hideEdit="mode !== 'edit'"
                :hideDelete="mode !== 'edit'"
                :serverFetch="
                  () => getTasks('task_template_header_id__is_pre_audit')
                "
                :deleteTask="(item) => onDeleteTask(item, 'is_pre_audit')"
                @editTask="onEditTask"
                @createTask="onCreateTask"
              />
            </app-expansion-panel>
            <!-- Audit Panel -->
            <app-expansion-panel :headerText="$t('menu.audit')">
              <app-audit
                :title="$t('menu.audit')"
                :taskItemHeader="taskItemHeader"
                :auditTasks="auditTasks"
                :loading="loading"
                :auditTasksLength="auditTasksLength"
                :mode="mode"
                :hideEdit="mode !== 'edit'"
                :hideDelete="mode !== 'edit'"
                :serverFetch="
                  () => getTasks('task_template_header_id__is_audit')
                "
                :deleteTask="(item) => onDeleteTask(item, 'is_audit')"
                @editTask="onEditTask"
                @createTask="onCreateTask"
              />
            </app-expansion-panel>
            <!-- Post Audit Panel -->
            <app-expansion-panel :headerText="$t('menu.postAudit')">
              <app-audit
                :title="$t('menu.postAudit')"
                :taskItemHeader="taskItemHeader"
                :auditTasks="postAuditTasks"
                :loading="loading"
                :auditTasksLength="postAuditTasksLength"
                :mode="mode"
                :hideEdit="mode !== 'edit'"
                :hideDelete="mode !== 'edit'"
                :serverFetch="
                  () => getTasks('task_template_header_id__is_post_audit')
                "
                :deleteTask="(item) => onDeleteTask(item, 'is_post_audit')"
                @editTask="onEditTask"
                @createTask="onCreateTask"
              />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
        <!-- Member Panel -->
        <app-expansion-panel :headerText="$t('menu.member')">
          <app-menu-form
            :headers="memberItemHeader"
            :server-items="memberItems"
            :client-items="memberItems"
            :loading="loading"
            :server-items-length="memberItemsLength"
            :hide-delete="mode !== 'edit'"
            :hide-edit="mode !== 'edit'"
            :events="{
              server: getMemberItems,
              delete: onDeleteMemberItem,
              edit: onEditMemberItem
            }"
          >
            <template v-slot:top>
              <app-menu-form-top
                :mode="mode"
                title="Member"
                :selected-length="selectedMemberItems.length"
                @on-delete="onDeleteMemberItem"
                dialog-max-width="900px"
                :dialog="dialogMemberItem"
                @show-dialog="dialogMemberItem = $event"
              >
                <template v-slot:dialogForm>
                  <v-card>
                    <v-card-text>
                      <v-container v-if="editId === null">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <app-input
                              name="typeMember"
                              type="select"
                              :label="$t('fields.type')"
                              :view="view"
                              v-model="editedMemberItem.type_member"
                              :events="{
                                change: onChangeTypeMember
                              }"
                              :items="[
                                {
                                  label: 'Team',
                                  value: 'team'
                                },
                                {
                                  label: 'Member',
                                  value: 'member'
                                }
                              ]"
                            ></app-input>
                          </v-col>
                        </v-row>
                        <v-row v-if="editedMemberItem.type_member === 'team'">
                          <v-col cols="12" sm="12">
                            <app-table
                              app="team"
                              model="teamheader"
                              :headers="teamItemHeader"
                              :clientItems="teamItems"
                              :loading="loading"
                              :hide-delete="mode !== 'edit'"
                              :hide-edit="mode !== 'edit'"
                              v-model="selectedTeamEmpItems"
                            />
                          </v-col>
                        </v-row>
                        <v-row v-if="editedMemberItem.type_member === 'member'">
                          <v-col cols="12" sm="12">
                            <app-table
                              app="contact"
                              model="contact"
                              :headers="empItemHeader"
                              :clientItems="empItems"
                              :loading="loading"
                              v-model="selectedTeamEmpItems"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container v-else>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <app-input
                              name="position"
                              :label="$t('fields.position')"
                              :view="view"
                              type="select-server"
                              v-model="editedMemberItem.position"
                              :binds="{
                                apiUrl:
                                  'audit/master-position/?active=true&state=approved'
                              }"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="closeMemberItem"
                      >
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="saveMemberItem">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </app-menu-form-top>
            </template>
          </app-menu-form>
        </app-expansion-panel>
        <!-- Matrix Panel -->
        <app-expansion-panel :headerText="$t('menu.matrix')">
          <v-expansion-panels multiple>
            <!-- Activity Matrix Panel -->
            <app-expansion-panel :headerText="$t('menu.activityMatrix')">
              <app-activity-matrix :isPlan="false" />
            </app-expansion-panel>
            <!-- Risk Matrix Panel -->
            <app-expansion-panel :headerText="$t('menu.riskMatrix')">
              <app-risk-matrix :isPlan="false" />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
        <!-- Report Panel -->
        <app-expansion-panel :headerText="$t('menu.report')">
          <v-expansion-panels multiple>
            <!-- Trial Balance Panel -->
            <app-expansion-panel :headerText="$t('menu.trialBalance')">
              <v-card flat>
                <v-card-text>
                  <!-- Select Trial Balance  -->
                  <v-row class="mb-2">
                    <v-col cols="3" lg="6" class="app-input--approve-wrapper">
                      <app-input
                        name="trialBalanceHeader"
                        type="select-server"
                        :label="$t('fields.trialBalance')"
                        :view="view"
                        :binds="{
                          apiUrl: `trial-balance/trial-balance-header/?contact_id=${formData.contact_id?.id}&state=approved`
                        }"
                        v-model="formData.trial_balance_header_id"
                        :events="{
                          change: onChangeTrialBalance
                        }"
                      />
                    </v-col>
                  </v-row>
                  <!-- Table Trial Balance -->

                  <app-table
                    server-side
                    ref="trialBalanceTable"
                    app="audit_trial_balance"
                    model="trialbalanceheader"
                    :hide-edit="true"
                    :hide-delete="true"
                    :isSelecteable="false"
                    :headers="trialBalanceHeaders"
                    :serverItems="trialBalanceItems"
                    :clientItems="trialBalanceItems"
                    :server-items-length="trialBalanceItemsLength"
                    :loading="loading"
                    @server="getTrialBalanceItems"
                    @client="getTrialBalanceClientItems"
                  >
                    <template
                      slot="body.append"
                      v-if="trialBalanceItemsLength > 0"
                    >
                      <tr>
                        <td></td>
                        <td></td>
                        <td class="text-subtitle-1">Totals</td>
                        <td class="text-subtitle-1 text-right">
                          {{ accountFieldFormat(sumBeginningBalance) }}
                        </td>
                        <td class="text-subtitle-1 text-right">
                          {{ accountFieldFormat(sumDebit) }}
                        </td>
                        <td class="text-subtitle-1 text-right">
                          {{ accountFieldFormat(sumCredit) }}
                        </td>
                        <td class="text-subtitle-1 text-right">
                          {{ accountFieldFormat(sumEndingBalance) }}
                        </td>
                        <td class="text-subtitle-1 text-right">
                          {{ accountFieldFormat(sumCalEndingBalance) }}
                        </td>
                        <td class="text-subtitle-1 text-right">
                          {{ accountFieldFormat(sumDiffEndingBalance) }}
                        </td>
                      </tr>
                    </template>
                  </app-table>
                </v-card-text>
              </v-card>
            </app-expansion-panel>
            <!-- Audit Trial Balance Panel -->
            <app-expansion-panel :headerText="$t('menu.auditTrialBalance')">
              <v-expansion-panels multiple>
                <!-- Trialbalance Before Audit Panel -->
                <app-expansion-panel
                  :headerText="$t('menu.trialBalanceBeforeAudit')"
                >
                  <v-card flat>
                    <v-card-text>
                      <app-table
                        server-side
                        ref="trialBalanceTable"
                        app="audit_trial_balance"
                        model="trialbalanceheader"
                        :hide-edit="true"
                        :hide-delete="true"
                        :isSelecteable="false"
                        :headers="adjustmentLineHeaders"
                        :serverItems="adjustments"
                        :clientItems="adjustments"
                        :server-items-length="adjustmentsCount"
                        :loading="loading"
                        @server="getAdjustments"
                      >
                        <template v-slot:item.link="{ item }">
                          <v-btn icon @click="showAdjDialog(item)">
                            <v-icon color="primary">mdi-link</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:foot>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td style="text-align: right">
                                <strong>Total</strong>
                              </td>
                              <td>
                                {{ accountingFormat(adjGrandEndingBalance) }}
                              </td>
                              <td>
                                {{ accountingFormat(adjGrandTotalDebit) }}
                              </td>
                              <td>
                                {{ accountingFormat(adjGrandTotalCredit) }}
                              </td>
                              <td>
                                {{
                                  accountingFormat(adjGrandAuditEndingBalance)
                                }}
                              </td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </template>
                      </app-table>
                    </v-card-text>
                  </v-card>
                  <v-dialog v-model="dialogAdj" max-width="900px">
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <app-table
                                ref="adjLineTable"
                                server-side
                                model="task"
                                app="audit"
                                :headers="adjLineHeaders"
                                hide-edit
                                hide-delete
                                :server-items="adjLines"
                                :server-items-length="adjLinesCount"
                                @server="getAdjLines"
                              >
                                <template
                                  v-slot:item.task_adjustment__task__id="{
                                    item
                                  }"
                                >
                                  <router-link
                                    :to="{
                                      name: 'taskEdit',
                                      params: {
                                        id: item.task_adjustment__task__id
                                      }
                                    }"
                                    target="_blank"
                                  >
                                    <v-icon color="primary">mdi-link</v-icon>
                                  </router-link>
                                </template>
                              </app-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="onAdjLinesClose"
                        >
                          {{ $t('btn.cancel') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </app-expansion-panel>
                <!-- Trialbalance After Audit Panel -->
                <app-expansion-panel
                  :headerText="$t('menu.trialBalanceAfterAudit')"
                >
                  <v-card flat>
                    <v-card-text>
                      <app-table
                        server-side
                        ref="trialBalanceTable"
                        app="audit_trial_balance"
                        model="trialbalanceheader"
                        :hide-edit="true"
                        :hide-delete="true"
                        :isSelecteable="false"
                        :headers="adjustmentLineHeaders"
                        :serverItems="adjustmentsAfter"
                        :clientItems="adjustmentsAfter"
                        :server-items-length="adjustmentsAfterCount"
                        :loading="loading"
                        @server="getAdjustmentsAfter"
                      >
                        <template v-slot:item.link="{ item }">
                          <v-btn icon @click="showAdjAfterDialog(item)">
                            <v-icon color="primary">mdi-link</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:foot>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td style="text-align: right">
                                <strong>Total</strong>
                              </td>
                              <td>
                                {{
                                  accountingFormat(adjAfterGrandEndingBalance)
                                }}
                              </td>
                              <td>
                                {{ accountingFormat(adjAfterGrandTotalDebit) }}
                              </td>
                              <td>
                                {{ accountingFormat(adjAfterGrandTotalCredit) }}
                              </td>
                              <td>
                                {{
                                  accountingFormat(
                                    adjAfterGrandAuditEndingBalance
                                  )
                                }}
                              </td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </template>
                      </app-table>
                    </v-card-text>
                  </v-card>
                  <v-dialog v-model="dialogAdjAfter" max-width="900px">
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <app-table
                                ref="adjAfterLineTable"
                                server-side
                                model="task"
                                app="audit"
                                :headers="adjLineHeaders"
                                hide-edit
                                hide-delete
                                :server-items="adjAfterLines"
                                :server-items-length="adjAfterLinesCount"
                                @server="getAdjAfterLines"
                              >
                                <template
                                  v-slot:item.task_adjustment__task__id="{
                                    item
                                  }"
                                >
                                  <router-link
                                    :to="{
                                      name: 'taskEdit',
                                      params: {
                                        id: item.task_adjustment__task__id
                                      }
                                    }"
                                    target="_blank"
                                  >
                                    <v-icon color="primary">mdi-link</v-icon>
                                  </router-link>
                                </template>
                              </app-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="onAdjAfterLinesClose"
                        >
                          {{ $t('btn.cancel') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </app-expansion-panel>
                <!-- Adj. and Rec. Summary Panel -->
                <app-expansion-panel :headerText="$t('menu.adjAndRecSummary')">
                  <v-toolbar flat>
                    <v-toolbar-title>Adj. & Rec. Summary</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <app-table
                        server-side
                        model="task"
                        app="task"
                        :headers="adjSumHeaders"
                        hide-edit
                        hide-delete
                        :server-items="adjSumItems"
                        :server-items-length="adjSumItemsCount"
                        @server="getAdjSumItems"
                      >
                        <template v-slot:item.link="{ item }">
                          <v-btn icon @click="showAdjSumDialog(item)">
                            <v-icon color="primary">mdi-link</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:foot>
                          <tfoot>
                            <tr>
                              <td></td>
                              <td></td>
                              <td style="text-align: right">
                                <strong>Total</strong>
                              </td>
                              <td>
                                {{ accountingFormat(adjSumGrandTotalDebit) }}
                              </td>
                              <td>
                                {{ accountingFormat(adjSumGrandTotalCredit) }}
                              </td>
                              <td></td>
                            </tr>
                          </tfoot>
                        </template>
                      </app-table>
                    </v-card-text>
                  </v-card>
                  <v-dialog v-model="dialogAdjSum" max-width="700px">
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <app-table
                                ref="adjSumLineTable"
                                server-side
                                model="task"
                                app="audit"
                                :headers="adjSumLineHeaders"
                                hide-edit
                                hide-delete
                                :server-items="adjSumLines"
                                :server-items-length="adjSumLinesCount"
                                @server="getAdjSumLines"
                              >
                                <template
                                  v-slot:item.task_adjustment__task__id="{
                                    item
                                  }"
                                >
                                  <router-link
                                    :to="{
                                      name: 'taskEdit',
                                      params: {
                                        id: item.task_adjustment__task__id
                                      }
                                    }"
                                    target="_blank"
                                  >
                                    <v-icon color="primary">mdi-link</v-icon>
                                  </router-link>
                                </template>
                              </app-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="onAdjSumLinesClose"
                        >
                          {{ $t('btn.cancel') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </app-expansion-panel>
                <!-- Conclusion Report Panel -->
                <app-expansion-panel :headerText="$t('menu.conclusionReport')">
                  <v-card flat>
                    <v-card-text>
                      <app-table
                        server-side
                        ref="conclusionReportTable"
                        app="audit_trial_balance"
                        model="trialbalanceheader"
                        :hide-edit="true"
                        :hide-delete="true"
                        :isSelecteable="false"
                        :headers="conclusionReportHeaders"
                        :serverItems="conclusionReport"
                        :clientItems="conclusionReport"
                        :server-items-length="conclusionReportCount"
                        :loading="loading"
                        @server="getReport"
                        @view="onEditTaskReport"
                      >
                      </app-table>
                    </v-card-text>
                  </v-card>
                </app-expansion-panel>
              </v-expansion-panels>
            </app-expansion-panel>
            <!-- Control BS/PL Panel -->
            <app-expansion-panel :headerText="$t('menu.controlBSPL')">
              <v-expansion-panels multiple>
                <!-- Control PL Panel -->
                <app-expansion-panel :headerText="$t('menu.controlPL')">
                  <app-control-pl
                    :loading="loading"
                    @update:loading="loading = $event"
                    :audit-header-id="$route.params?.id"
                    :contact-id="formData.contact_id?.id"
                  />
                </app-expansion-panel>
                <!-- Control BS Panel -->
                <app-expansion-panel :headerText="$t('menu.controlBS')">
                  <app-control-bs
                    :loading="loading"
                    @update:loading="loading = $event"
                    :audit-header-id="$route.params?.id"
                    :contact-id="formData.contact_id?.id"
                    :is-public-company="isPublicCompany"
                    :is-limited-partnership="isLimitedPartnership"
                    :is-foreign="isForeign"
                    :is-joint-venture="isJointVenture"
                  />
                </app-expansion-panel>
              </v-expansion-panels>
            </app-expansion-panel>

            <!-- Financial Report Panel -->
            <app-expansion-panel :headerText="$t('menu.financialReport')">
              <v-expansion-panels multiple>
                <!-- Statement of Changes Equity Panel -->
                <app-expansion-panel
                  :headerText="$t('menu.statementOfChangesInEquity')"
                >
                  <app-multiple-files
                    :loading="loading"
                    @update:loading="loading = $event"
                    :view="view"
                    :mode="mode"
                    :get-url="`audit/audit-header-file/?audit_header_id=${$route.params.id}&audit_file_type=Statement of Changes Equity`"
                    :save-url="`audit/audit-header-file/?audit_header_id=${$route.params.id}&audit_file_type=Statement of Changes Equity`"
                  />
                </app-expansion-panel>
                <!-- Statement of Changes Equity Panel -->
                <app-expansion-panel
                  :headerText="$t('menu.statementOfCashFlow')"
                >
                  <app-multiple-files
                    :loading="loading"
                    @update:loading="loading = $event"
                    :view="view"
                    :mode="mode"
                    :get-url="`audit/audit-header-file/?audit_header_id=${$route.params.id}&audit_file_type=Statement of Cash Flow`"
                    :save-url="`audit/audit-header-file/?audit_header_id=${$route.params.id}&audit_file_type=Statement of Cash Flow`"
                  />
                </app-expansion-panel>
                <!-- Consolidated Financial Statement Panel -->
                <app-expansion-panel
                  :headerText="$t('menu.consolidatedFinancialStatement')"
                >
                  <app-multiple-files
                    :loading="loading"
                    @update:loading="loading = $event"
                    :view="view"
                    :mode="mode"
                    :get-url="`audit/audit-header-file/?audit_header_id=${$route.params.id}&audit_file_type=Consolidated Financial Statement`"
                    :save-url="`audit/audit-header-file/?audit_header_id=${$route.params.id}&audit_file_type=Consolidated Financial Statement`"
                  />
                </app-expansion-panel>
              </v-expansion-panels>
            </app-expansion-panel>
            <!-- Auditor Report Panel -->
            <app-expansion-panel :headerText="$t('menu.auditorReport')">
              <app-report
                :mode="mode"
                :view="view"
                report-type="Auditor Report"
              />
            </app-expansion-panel>
            <!-- Note To Financial Report Panel -->
            <app-expansion-panel :headerText="$t('menu.noteToFinancialReport')">
              <app-report
                :mode="mode"
                :view="view"
                report-type="Note to Financial Statement"
              />
            </app-expansion-panel>
            <!-- Management Report Panel -->
            <app-expansion-panel :headerText="$t('menu.managementReport')">
              <app-report
                :mode="mode"
                :view="view"
                report-type="Management Report"
              />
            </app-expansion-panel>
            <!-- Follow Up Report Panel -->
            <app-expansion-panel :headerText="$t('menu.followUpReport')">
              <app-report
                :mode="mode"
                :view="view"
                report-type="Follow Up Report"
              />
            </app-expansion-panel>
            <!-- Timesheet Panel -->
            <app-expansion-panel :headerText="$t('menu.timesheet')">
              <v-expansion-panels multiple>
                <!-- timesheet by position Panel -->
                <app-expansion-panel
                  :headerText="$t('menu.timesheetByPosition')"
                >
                  <app-timesheet-position />
                </app-expansion-panel>
                <!-- timesheet by member Panel -->
                <app-expansion-panel :headerText="$t('menu.timesheetByMember')">
                  <app-timesheet-member />
                </app-expansion-panel>
              </v-expansion-panels>
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
      </v-expansion-panels>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput'
import AppTable from '@components/AppTable.vue'
import AppExpansionPanel from '@components/AppExpansionPanel'
import AppAuditPlan from '@components/view/AppAuditPlan'
import AppAudit from '@components/view/AppAudit'
import AppRiskMatrix from '@components/view/AppRiskMatrix'
import AppActivityMatrix from '@components/view/AppActivityMatrix'
import AppTimesheetPosition from '@components/view/AppTimesheetPosition'
import AppTimesheetMember from '@components/view/AppTimesheetMember'
import AppMenuForm from '@components/AppMenuForm'
import AppMenuFormTop from '@components/view/AppMenuFormTop'
import AppControlPl from '@components/view/AppControlPl.vue'
import AppControlBs from '@components/view/AppControlBs.vue'
import AppReport from '@components/view/AppReport'
import { defaultTableParams } from '@utils/app-table'
import { accounting, hoursMinutes, percentFormat } from '@utils/number-format'
import AppMultipleFiles from '@components/view/AppMultipleFiles.vue'

export default {
  name: 'audit-plan-form',
  components: {
    AppMultipleFiles,
    AppInput,
    AppFormView,
    AppTable,
    AppExpansionPanel,
    AppAuditPlan,
    AppAudit,
    AppTimesheetPosition,
    AppTimesheetMember,
    AppRiskMatrix,
    AppActivityMatrix,
    AppMenuForm,
    AppMenuFormTop,
    AppControlPl,
    AppControlBs,
    AppReport
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      tab: null,
      loading: false,
      approveItems: [
        'approve_name',
        'approve_audit_period',
        'approve_audit_year',
        'approve_chart_of_account_header_id',
        'approve_contact_id',
        'approve_business_industry',
        'approve_business_category',
        'approve_business_type',
        'approve_invoice',
        'approve_invoice_item',
        'approve_audit_plan_id',
        'approve_prepare_by',
        'approve_prepare_date',
        'approve_review_by',
        'approve_review_date',
        'approve_start_date',
        'approve_end_date',
        'approve_accounting_period_beginning',
        'approve_accounting_period_ending'
      ],
      editId: null,
      apiMethod: 'post',
      trialBalanceItems: [],
      trialBalanceClientItems: [],
      trialBalanceItemsLength: 0,
      selected: [],
      selectedTasks: [],
      preAuditTasks: [],
      preAuditTasksLength: 0,
      auditTasks: [],
      auditTasksLength: 0,
      postAuditTasks: [],
      postAuditTasksLength: 0,
      memberItems: [],
      memberItemsLength: 0,
      dialogMemberItem: false,
      editedMemberItem: {},
      selectedMemberItems: [],
      teamItems: [],
      empItems: [],
      selectedTeamEmpItems: [],
      adjSumItems: [],
      adjSumItemsCount: 0,
      adjSumGrandTotalDebit: 0,
      adjSumGrandTotalCredit: 0,
      dialogAdjSum: false,
      editingAdjSum: null,
      adjSumLines: [],
      adjSumLinesCount: 0,
      adjItems: [],
      adjItemsCount: 0,
      adjGrandTotalDebit: 0,
      adjGrandTotalCredit: 0,
      dialogAdj: false,
      editingAdj: null,
      adjLines: [],
      adjLinesCount: 0,
      editingAdjustment: {},
      editingAdjustmentLine: {},
      adjustments: [],
      adjustmentsCount: 0,
      adjustmentsAfter: [],
      adjustmentsAfterCount: 0,
      adjGrandEndingBalance: 0,
      adjGrandAuditEndingBalance: 0,
      conclusionReport: [],
      conclusionReportCount: 0,
      adjAfterItems: [],
      adjAfterItemsCount: 0,
      adjAfterGrandTotalDebit: 0,
      adjAfterGrandTotalCredit: 0,
      dialogAdjAfter: false,
      editingAdjAfter: null,
      adjAfterLines: [],
      adjAfterLinesCount: 0,
      adjAfterGrandEndingBalance: 0,
      adjAfterGrandAuditEndingBalance: 0,
      isPublicCompany: false,
      isLimitedPartnership: false,
      isForeign: false,
      isJointVenture: false,
      categoryUrl: null,
      buztypeUrl: null,
      auditHeaderId: null
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    trialBalanceHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chartOfAccountDetail_id.code',
          hideFilter: true
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chartOfAccountDetail_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.customerAccountType'),
          value: 'chartOfAccountDetail_id.customAccountType',
          hideFilter: true
        },
        {
          text: this.$t('fields.beginningBalance'),
          value: 'beginning_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.debit'),
          value: 'debit',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.credit'),
          value: 'credit',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.endingBalance'),
          value: 'ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.calculatedEndingBalance'),
          value: 'calculated_ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.different'),
          value: 'calculated_diff_ending_balance',
          align: 'right',
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        }
      ]
    },
    adjustmentLineHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail_code',
          groupable: false,
          hideFilter: true,
          sortable: false
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail_name',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.customerAccountType'),
          value: 'chart_of_account_detail_type',
          groupable: false,
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.endingBalance'),
          value: 'ending_balance',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.debit'),
          value: 'total_debit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.credit'),
          value: 'total_credit',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: this.$t('fields.auditEndingBalance'),
          value: 'audit_ending_balance',
          groupable: false,
          sortable: false,
          hideFilter: true,
          accountFormat: true
        },
        {
          text: '',
          sortable: false,
          hideFilter: true,
          value: 'link'
        }
      ]
    },
    conclusionReportHeaders() {
      return [
        {
          text: this.$t('fields.taskName'),
          value: 'task_name'
        },
        {
          text: this.$t('fields.auditorReport'),
          value: 'is_audit_report',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.financeReport'),
          value: 'is_finance',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.managementReport'),
          value: 'is_management_report',
          check: true,
          hideFilter: true
        },
        {
          text: this.$t('fields.followUpReport'),
          value: 'is_follow_up_report',
          check: true,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    taskItemHeader() {
      return [
        {
          text: this.$t('fields.taskName'),
          value: 'name'
        },
        {
          text: this.$t('fields.assignTo'),
          value: 'assign_to.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.assignDate'),
          value: 'assign_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.preparedBy'),
          value: 'prepare_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.prepareDate'),
          value: 'prepare_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewedBy'),
          value: 'review_by.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.reviewDate'),
          value: 'review_date',
          hideFilter: true
        },
        {
          text: this.$t('fields.deadline'),
          value: 'deadline',
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions_view',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    memberItemHeader() {
      return [
        {
          text: this.$t('fields.memberName'),
          value: 'contact_id.name'
        },
        {
          text: this.$t('fields.position'),
          value: 'position.name'
        },
        {
          text: this.$t('fields.team'),
          value: 'team_id.name'
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    teamItemHeader() {
      return [
        {
          text: this.$t('fields.team'),
          value: 'name'
        },
        {
          text: this.$t('fields.regionName'),
          value: 'teamRegion_id.region_id.name'
        },
        {
          text: this.$t('fields.divisionName'),
          value: 'teamRegion_id.division_id.name'
        },
        {
          text: this.$t('fields.subDivisionName'),
          value: 'teamRegion_id.sub_division_id.name'
        }
      ]
    },
    empItemHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'name'
        }
      ]
    },
    sumBeginningBalance() {
      return this.sumField('beginning_balance')
    },
    sumDebit() {
      return this.sumField('debit')
    },
    sumCredit() {
      return this.sumField('credit')
    },
    sumEndingBalance() {
      return this.sumField('ending_balance')
    },
    sumCalEndingBalance() {
      return this.sumField('calculated_ending_balance')
    },
    sumDiffEndingBalance() {
      return this.sumField('calculated_diff_ending_balance')
    },
    adjSumHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail__code'
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail__name'
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'sum_debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'sum_credit',
          accountFormat: true
        },
        {
          text: '',
          sortable: false,
          hideFilter: true,
          value: 'link'
        }
      ]
    },
    adjSumLineHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail__code'
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail__name'
        },
        {
          text: this.$t('fields.taskRef'),
          value: 'task_adjustment__task__reference'
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'task_adjustment__ref'
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          accountFormat: true
        },
        {
          text: '',
          sortable: false,
          hideFilter: true,
          value: 'task_adjustment__task__id'
        }
      ]
    },
    adjLineHeaders() {
      return [
        {
          text: this.$t('fields.accountCode'),
          value: 'chart_of_account_detail__code'
        },
        {
          text: this.$t('fields.accountName'),
          value: 'chart_of_account_detail__name'
        },
        {
          text: this.$t('fields.customerAccountType'),
          value: 'chart_of_account_detail__customAccountType'
        },
        {
          text: this.$t('fields.taskRef'),
          value: 'task_adjustment__task__reference'
        },
        {
          text: this.$t('fields.adjRef'),
          value: 'task_adjustment__ref'
        },
        {
          text: this.$t('fields.adjDebit'),
          value: 'debit',
          accountFormat: true
        },
        {
          text: this.$t('fields.adjCredit'),
          value: 'credit',
          accountFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'task_adjustment__adj_note'
        },
        {
          text: '',
          sortable: false,
          hideFilter: true,
          value: 'task_adjustment__task__id'
        }
      ]
    }
  },
  watch: {
    'formData.contact_id': function (value) {
      this.isPublicCompany = false
      this.isLimitedPartnership = false
      this.isForeign = false
      this.isJointVenture = false
      if (value?.prefix_id)
        this.$api({
          method: 'get',
          url: `contact/contact-prefix/${value.prefix_id?.id}/`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.isPublicCompany = data.detail.is_public_company
          this.isLimitedPartnership = data.detail.is_limited_partnership
          this.isForeign = data.detail.is_foreign
          this.isJointVenture = data.detail.is_joint_venture
        })
    },
    'formData.name': function (value) {
      if (this.$route.name === 'auditEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'auditEdit',
          text: value,
          to: { name: 'auditEdit', params: { id: this.$route.params.id } }
        })
      }
    }
  },
  created() {
    this.auditHeaderId = this.$route.params.id || 0
    if (this.$route.name === 'auditCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'auditCreate'
      })
    }
  },
  methods: {
    accountingFormat: accounting,
    hoursMinutesFormat: hoursMinutes,
    percentFormat: percentFormat,
    prepareData(data) {
      data.chart_of_account_header_id = data?.chart_of_account_header_id?.id
      data.contact_id = data?.contact_id?.id
      data.audit_plan_id = data?.audit_plan_id?.id
      data.trial_balance_header_id = data?.trial_balance_header_id?.id
      data.industry_id = data?.industry_id?.id
      data.business_cate_id = data?.business_cate_id?.id
      data.business_type_id = data?.business_type_id?.id
      data.prepare_by = data?.prepare_by?.id
      data.review_by = data?.review_by?.id
      return data
    },
    //----Trial Balance Functions----

    onChangeTrialBalance() {
      this.$refs.trialBalanceTable.reset()
    },
    async getTrialBalanceItems(options = null) {
      this.loading = true
      const trialBalanceHeader_id = this.formData?.trial_balance_header_id?.id
      const offset = (options.page - 1) * options.itemsPerPage
      const ordering = (
        options?.sortBy?.reduce(
          (acc, value, index) => [
            ...acc,
            `${options.sortDesc[index] ? '-' : ''}${value.replace('.', '__')}`
          ],
          []
        ) ?? ['chartOfAccountDetail_id__code']
      ).join(',')
      const params = {
        limit: options.itemsPerPage,
        offset: offset,
        search: options.search,
        ordering: ordering,
        ...options.multiSearch,
        trialBalanceHeader_id: trialBalanceHeader_id
      }

      if (
        trialBalanceHeader_id !== null &&
        trialBalanceHeader_id !== undefined
      ) {
        await this.$api({
          method: 'get',
          url: 'trial-balance/trial-balance-detail/',
          params: params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.trialBalanceItemsLength = data.count
          this.trialBalanceItems = data.results
          this.trialBalanceClientItems = data.results
        })
      }
      this.loading = false
    },
    getTrialBalanceClientItems(multiSearch) {
      this.trialBalanceClientItems = this.trialBalanceItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    accountFieldFormat(value) {
      if (value < 0) {
        const formatted = Math.abs(value)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return `(${formatted})`
      } else {
        return `${value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} `
      }
    },

    sumField(key) {
      // sum data in give key (property)
      return this.trialBalanceItems.reduce(
        (a, b) => parseFloat(a) + parseFloat(b[key] || 0),
        parseFloat(0)
      )
    },
    getBusinessCateItem() {
      var url = ''
      if (
        this.formData.industry_id !== null &&
        this.formData.industry_id !== undefined
      ) {
        url =
          'doc-template/category/?active=true&industry_id=' +
          this.formData.industry_id?.id +
          '&contact_id=' +
          this.formData.contact_id?.id
      } else {
        url =
          'doc-template/category/?active=true' +
          '&contact_id=' +
          this.formData.contact_id?.id
      }

      this.categoryUrl = url
    },
    getBusinessTypeItem() {
      var url = ''
      if (
        this.formData.business_cate_id !== null &&
        this.formData.business_cate_id !== undefined
      ) {
        url =
          'doc-template/buztype/?active=true&business_cate_id=' +
          this.formData.business_cate_id?.id +
          '&contact_id=' +
          this.formData.contact_id?.id
      } else {
        url =
          'doc-template/buztype/?active=true' +
          '&contact_id=' +
          this.formData.contact_id?.id
      }
      this.buztypeUrl = url
    },

    //----Task Functions----
    getTasks(auditType = '') {
      this.loading = true
      const audit_id = this.formData?.id
      if (audit_id) {
        this.$api({
          method: 'get',
          url: `/task/task/?audit_id=${audit_id}&${auditType}=true`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          if (auditType === 'task_template_header_id__is_pre_audit') {
            this.preAuditTasksLength = data.count
            this.preAuditTasks = data.results
          } else if (auditType === 'task_template_header_id__is_audit') {
            this.auditTasksLength = data.count
            this.auditTasks = data.results
          } else if (auditType === 'task_template_header_id__is_post_audit') {
            this.postAuditTasksLength = data.count
            this.postAuditTasks = data.results
          }
        })
      }
      this.loading = false
    },
    onCreateTask() {
      this.$router.push({
        name: 'taskCreate',
        query: {
          audit_plan_id: this.formData?.audit_plan_id?.id,
          audit_id: this.formData.id,
          from: 'audit-header',
          accounting_period_beginning:
            this.formData.accounting_period_beginning,
          accounting_period_ending: this.formData.accounting_period_ending
        }
      })
    },
    onEditTask(item) {
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.id },
        query: {
          audit_plan_id: this.formData?.audit_plan_id?.id,
          audit_id: this.formData.id,
          from: 'audit-header'
        }
      })
    },
    onEditTaskReport(item) {
      this.$router.push({
        name: 'taskEdit',
        params: { id: item.task_id },
        query: {
          audit_plan_id: this.formData?.audit_plan_id?.id,
          audit_id: this.formData.id,
          from: 'audit-header'
        }
      })
    },
    onDeleteTask(items, auditType = '') {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getTasks(auditType)
        })
      })
      this.loading = false
    },
    //----Members Functions----
    getTeams() {
      this.$api({
        method: 'get',
        url: `team/team-header/?state=approved`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.teamItems = data.results
      })
      this.loading = false
    },
    getEmployee() {
      this.$api({
        method: 'get',
        url: `contact/contact/?state=approved&is_employee=true`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        this.empItems = data.results
      })
      this.loading = false
    },
    getMemberItems() {
      const audit_header_id = this.$route.params.id
      if (audit_header_id) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-member/?audit_header_id=${audit_header_id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.memberItemsLength = data.count
          this.memberItems = data.results
        })
      }
      this.loading = false
    },
    closeMemberItem() {
      this.dialogMemberItem = false
      this.$nextTick(() => {
        this.editedMemberItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.editId = null
      this.selectedTeamEmpItems = []
      this.apiMethod = 'post'
      this.loading = false
    },
    async saveMemberItem() {
      this.loading = true
      const data = {
        audit_header_id: Number(this.$route.params.id),
        audit_plan_header_id: Number(this.formData.audit_plan_id.id)
      }

      if (this.editId) {
        if (this.editedMemberItem.contact_id) {
          data.contact_id = this.editedMemberItem.contact_id.id
        }
        data.position = this.editedMemberItem.position?.id
          ? this.editedMemberItem.position.id
          : this.editedMemberItem.position
      } else {
        data.type = this.editedMemberItem.type_member
        data.ids = this.selectedTeamEmpItems.map((value) => value.id)
      }
      await this.$api({
        method: this.apiMethod,
        url: `/audit/audit-plan-member/${this.editId ? this.editId + '/' : ''}`,
        data
      })
      this.loading = false
      this.getMemberItems()
      this.closeMemberItem()
    },
    onDeleteMemberItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-plan-member/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selectedMemberItems = []
          this.getMemberItems()
        })
      })
      this.loading = false
    },
    onEditMemberItem(item) {
      this.editedMemberItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogMemberItem = true
    },
    onChangeTypeMember(value) {
      this.editedMemberItem.type_member = value
      if (value === 'team') {
        this.getTeams()
      } else if (value === 'member') {
        this.getEmployee()
      }
    },
    async getAdjSumItems(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/${this.$route.params.id}/adj_rec_sum/`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjSumItems = data.results
        this.adjSumItemsCount = data.count

        this.adjSumGrandTotalDebit = data.grand_total_debit ?? 0
        this.adjSumGrandTotalCredit = data.grand_total_credit ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showAdjSumDialog(item) {
      this.editingAdjSum = Object.assign({}, item)
      this.dialogAdjSum = true
      this.$refs.adjSumLineTable.onServer()
    },
    onAdjSumLinesClose() {
      this.dialogAdjSum = false
      this.editingAdjSum = null
    },
    async getAdjSumLines(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/${this.$route.params.id}/adj_rec_sum/${this.editingAdjSum.chart_of_account_detail__code}/`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjSumLines = data.results
        this.adjSumLinesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getReport() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        this.$api({
          method: 'get',
          url: `task/task-conclusion-report/?audit_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.conclusionReport = data
          this.conclusionReportCount = data.length
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    // -- adjustment function --
    getAdjustments() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        this.$api({
          method: 'get',
          url: `audit/audit-trial-before/?audit_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.adjustments = data.results
          this.adjustmentsCount = data.count

          this.adjGrandTotalDebit = data.grand_total_debit ?? 0
          this.adjGrandTotalCredit = data.grand_total_credit ?? 0
          this.adjGrandEndingBalance = data.grand_total_ending_balance ?? 0
          this.adjGrandAuditEndingBalance =
            data.grand_total_audit_ending_balance ?? 0
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showAdjDialog(item) {
      this.editingAdj = Object.assign({}, item)
      this.dialogAdj = true
      this.$refs.adjLineTable.onServer()
    },
    onAdjLinesClose() {
      this.dialogAdj = false
      this.editingAdj = null
    },
    async getAdjLines(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/${this.$route.params.id}/adj_rec_sum/${this.editingAdj.chart_of_account_detail_code}/?task_adjustment__is_before_audit=True`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjLines = data.results
        this.adjLinesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    // -- adjustment after function --
    getAdjustmentsAfter() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        this.$api({
          method: 'get',
          url: `audit/audit-trial-after/?audit_id=${this.$route.params.id}`,
          params: params,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.adjustmentsAfter = data.results
          this.adjustmentsAfterCount = data.count

          this.adjAfterGrandTotalDebit = data.grand_total_debit ?? 0
          this.adjAfterGrandTotalCredit = data.grand_total_credit ?? 0
          this.adjAfterGrandEndingBalance = data.grand_total_ending_balance ?? 0
          this.adjAfterGrandAuditEndingBalance =
            data.grand_total_audit_ending_balance ?? 0
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    showAdjAfterDialog(item) {
      this.editingAdjAfter = Object.assign({}, item)
      this.dialogAdjAfter = true
      this.$refs.adjAfterLineTable.onServer()
    },
    onAdjAfterLinesClose() {
      this.dialogAdjAfter = false
      this.editingAdjAfter = null
    },
    async getAdjAfterLines(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit/${this.$route.params.id}/adj_rec_sum/${this.editingAdjAfter.chart_of_account_detail_code}/?task_adjustment__is_after_audit=True`,
          params: params,
          hideSuccessAlert: true
        })
        this.adjAfterLines = data.results
        this.adjAfterLinesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
