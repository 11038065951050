<template>
  <app-form-view
    app="contact"
    model="businessunit"
    api-url="contact/business-unit/"
    :title="$t('menu.businessUnit')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            type="text"
            rules="required"
            :label="$t('fields.businessUnit')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveBuzUnitName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_buz_unit_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="Description"
            type="html-editor"
            :label="$t('fields.description')"
            :view="view"
            v-model="formData.description"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDescription"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_buz_unit_description"
          />
        </v-col>
      </v-row>
      <v-card>
        <v-tabs background-color="primary" dark v-model="tab">
          <v-tab> Business </v-tab>
          <!-- Tab Bussiness -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <template>
                  <app-table
                    server-side
                    app="contact"
                    model="businessunitbusinesstype"
                    :hide-edit="mode !== 'edit'"
                    :hide-delete="mode !== 'edit'"
                    :headers="buzUnitbuzTypeHeaders"
                    :search="search"
                    :serverItems="buzUnitbuzTypeItems"
                    :clientItems="buzUnitbuzTypeItems"
                    :loading="loading"
                    v-bind="binds"
                    v-model="selected"
                    :server-items-length="buzUnitbuzTypeServerItemsLength"
                    @server="getbuzUnitBuzTypeItems"
                    @client="getClientBuzUnitbuzTypeItems"
                    @edit="onEditBusiness"
                    @delete="onDeleteBuzUnitbuzTypeItem"
                  >
                    <template v-slot:top>
                      <div class="app-list-view">
                        <div class="app-list-view-header">
                          <v-toolbar flat>
                            <v-toolbar-title>Business</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <!-- Dialog Bussiness Wizard Pop -->
                            <v-dialog v-model="dialogBusiness" max-width="1500">
                              <template v-slot:activator="{ on, attrs }">
                                <slot name="actions">
                                  <slot name="prefix-actions" />
                                  <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    v-show="mode === 'edit'"
                                    @click="openDialogBuzUnitbuzType"
                                  >
                                    New Item
                                  </v-btn>
                                  <v-btn
                                    v-show="selected.length && mode === 'edit'"
                                    color="error"
                                    @click="
                                      onDeleteBuzUnitbuzTypeItem(selected)
                                    "
                                  >
                                    {{ $t('btn.delete') }}
                                  </v-btn>

                                  <slot name="suffix-actions" />
                                </slot>
                              </template>
                              <v-card flat>
                                <v-card-text class="pt-4">
                                  <v-toolbar dense color="primary white--text">
                                    <v-toolbar-title>
                                      {{ $t('label.selectBusiness') }}
                                    </v-toolbar-title>
                                  </v-toolbar>
                                  <app-form
                                    app="contact"
                                    model="businessunitbusinesstype"
                                    api-url="contact/business-unit-type/"
                                    :title="$t('menu.business')"
                                    v-model="formData"
                                    :state="formData.state"
                                    hideSave
                                    hideCancel
                                  >
                                    <template>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            v-model="editedItem.industry"
                                            name="industry"
                                            type="select-server"
                                            :label="$t('fields.industry')"
                                            :view="view"
                                            :binds="{
                                              apiUrl:
                                                'doc-template/industry/?active=true'
                                            }"
                                            @input="getBusinessCateItem"
                                          ></app-input>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            v-model="
                                              editedItem.business_cate_id
                                            "
                                            name="category"
                                            type="select-server"
                                            :label="$t('fields.category')"
                                            :view="view"
                                            :binds="{
                                              apiUrl: categoryUrl
                                            }"
                                            @input="getBusinessTypeItem"
                                          ></app-input>
                                        </v-col>
                                      </v-row>
                                      <v-row class="pt-4">
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            v-model="
                                              editedItem.business_type_id
                                            "
                                            name="buztype"
                                            type="select-server"
                                            :label="$t('fields.businessType')"
                                            :view="view"
                                            :binds="{
                                              apiUrl: buztypeUrl
                                            }"
                                          ></app-input>
                                        </v-col>
                                        <v-col
                                          cols="12"
                                          lg="6"
                                          class="app-input--approve-wrapper"
                                        >
                                          <app-input
                                            v-model="editedItem.is_primary"
                                            name="is_primary"
                                            type="switch"
                                            :label="$t('fields.main')"
                                            :view="view"
                                          ></app-input>
                                        </v-col>
                                      </v-row>
                                    </template>
                                  </app-form>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDialogBuzUnitbuzType"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn color="primary" @click="saveBusiness">
                                    {{ $t('btn.save') }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </div>
                      </div>
                    </template>
                  </app-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppTable from '@components/AppTable'

export default {
  name: 'buz-unit-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  props: {
    hideEdit: {
      type: Boolean,
      default: true
    },
    hideDelete: {
      type: Boolean,
      default: false
    },
    binds: {
      type: Object,
      default: () => ({})
    },
    query: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: ['approve_buz_unit_name', 'approve_buz_unit_description'],
      tab: null,
      loading: false,
      search: '',
      buzUnitbuzTypeItems: [],
      selectedHeader: [],
      buzUnitbuzTypeServerItemsLength: 0,
      dialogBusiness: false,
      editedItem: {},
      selected: [],
      categoryUrl: null,
      buztypeUrl: null,
      editId: null,
      apiMethod: 'post'
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'businessUnitCreate'
    })
  },
  watch: {
    search(newValue, oldValue) {
      this.serverSide && newValue !== oldValue && this.onServer()
    },
    'formData.name': function (value) {
      if (this.$route.name === 'businessUnitEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'businessUnitEdit',
          text: value ?? 'N/A',
          to: {
            name: 'businessUnitEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    buzUnitbuzTypeHeaders() {
      return [
        { text: this.$t('fields.businessIndustry'), value: 'industry.name' },
        {
          text: this.$t('fields.businessCategory'),
          value: 'business_cate_id.name'
        },
        {
          text: this.$t('fields.businessType'),
          value: 'business_type_id.name'
        },
        {
          text: this.$t('fields.main'),
          value: 'is_primary',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    getBusinessCateItem() {
      var url = ''
      if (
        this.editedItem.industry !== null &&
        this.editedItem.industry !== undefined
      ) {
        url =
          'doc-template/category/?active=true&industry_id=' +
          this.editedItem.industry.id
      } else {
        url = 'doc-template/category/?active=true'
      }

      this.categoryUrl = url
    },
    getBusinessTypeItem() {
      var url = ''
      if (
        this.editedItem.business_cate_id !== null &&
        this.editedItem.business_cate_id !== undefined
      ) {
        url =
          'doc-template/buztype/?active=true&business_cate_id=' +
          this.editedItem.business_cate_id.id
      } else {
        url = 'doc-template/buztype/?active=true'
      }

      this.buztypeUrl = url
    },
    getbuzUnitBuzTypeItems(options = null) {
      this.loading = true

      var params = {
        ...this.query
      }

      if (options !== null) {
        params = {
          ...options.multiSearch,
          ...this.query
        }
      }

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `contact/business-unit-type?business_unit_id=${this.$route.params.id}`,
          hideSuccessAlert: true,
          params
        }).then(({ data }) => {
          this.buzUnitbuzTypeServerItemsLength = data.count
          this.buzUnitbuzTypeItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    getClientBuzUnitbuzTypeItems(multiSearch) {
      this.clientItems = this.serverItems.filter((item) => {
        return Object.entries(multiSearch).every(([key, value]) => {
          if (typeof item[key.slice(0, key.lastIndexOf('.'))] === 'object') {
            return item[key.slice(0, key.lastIndexOf('.'))][
              key.slice(key.lastIndexOf('.') + 1)
            ]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          } else {
            return item[key]
              .toString()
              .toUpperCase()
              .includes(value.toString().toUpperCase())
          }
        })
      })
    },
    onDeleteBuzUnitbuzTypeItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `contact/business-unit-type/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getbuzUnitBuzTypeItems()
        })
      })
      this.loading = false
    },
    openDialogBuzUnitbuzType() {
      this.dialogBusiness = true
    },
    closeDialogBuzUnitbuzType() {
      this.apiMethod = 'post'
      this.dialogBusiness = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveBusiness() {
      this.loading = true
      const data = { ...this.editedItem }
      data.business_unit_id = this.$route.params.id

      if (this.editedItem.is_primary) {
        data.is_primary = 'True'
      } else {
        data.is_primary = 'False'
      }
      if (
        this.editedItem.industry !== null &&
        this.editedItem.industry !== undefined
      ) {
        data.industry = this.editedItem.industry.id
        if (
          this.editedItem.business_cate_id !== null &&
          this.editedItem.business_cate_id !== undefined
        ) {
          data.business_cate_id = this.editedItem.business_cate_id.id
        }
        if (
          this.editedItem.business_type_id !== null &&
          this.editedItem.business_type_id !== undefined
        ) {
          data.business_type_id = this.editedItem.business_type_id.id
        }
      }
      await this.$api({
        method: this.apiMethod,
        url: `contact/business-unit-type/${
          this.editId ? this.editId + '/' : ''
        }`,
        data
      })
      this.loading = false
      this.apiMethod = 'post'
      this.editId = null
      this.editedItem = {}
      this.closeDialogBuzUnitbuzType()
      this.getbuzUnitBuzTypeItems()
    },
    onEditBusiness(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogBusiness = true
    }
  }
}
</script>
