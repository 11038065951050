const defaultTableParams = (options = null) => {
  let params = {}
  if (options) {
    const offset = (options.page - 1) * options.itemsPerPage
    const ordering = options.sortBy
      ? options.sortBy
          .reduce(
            (acc, value, index) => [
              ...acc,
              `${
                options.sortDesc && options.sortDesc[index] ? '-' : ''
              }${value.replace('.', '__')}`
            ],
            []
          )
          .join(',')
      : null

    params = {
      ...params,
      limit: options.itemsPerPage,
      offset: offset,
      search: options.search,
      ordering: ordering,
      ...options.multiSearch
    }
  }
  return params
}
// node_modules/.pnpm/vuetify@2.7.2_vue@2.7.16/node_modules/vuetify/src/util/helpers.ts
function getNestedValue(obj, path, fallback) {
  const last = path.length - 1

  if (last < 0) return obj === undefined ? fallback : obj

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback
    }
    obj = obj[path[i]]
  }

  if (obj == null) return fallback

  return obj[path[last]] === undefined ? fallback : obj[path[last]]
}
function getObjectValueByPath(obj, path, fallback) {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') return fallback
  if (obj[path] !== undefined) return obj[path]
  path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  path = path.replace(/^\./, '') // strip a leading dot
  return getNestedValue(obj, path.split('.'), fallback)
}

export { defaultTableParams, getNestedValue, getObjectValueByPath }
