<template>
  <app-menu-form
    :mode="mode"
    :headers="reportHeaders"
    :serverItems="reports"
    :clientItems="reports"
    :loading="loading"
    :serverItemsLength="reports.length"
    :hide-edit="mode !== 'edit'"
    :hide-delete="mode !== 'edit'"
    :events="eventHandlers"
  >
    <template v-slot:top>
      <div>
        <app-menu-form-top
          title=""
          :mode="mode"
          :dialog="dialog"
          @show-dialog="dialog = $event"
        >
          <template v-slot:suffix-actions>
            <p class="px-12 align-self-center mb-0 text-capitalize">
              {{ status }}
            </p>
          </template>
          <template v-slot:dialogForm>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="app-input--approve-wrapper">
                      <app-input
                        name="sequence"
                        type="number"
                        :label="$t('fields.sequence')"
                        :view="view"
                        v-model="editingReport.sequence"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="app-input--approve-wrapper">
                      <v-text-field
                        name="name"
                        :label="$t('fields.name')"
                        :view="view"
                        v-model="editingReport.name"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex align-center">
                      <v-container class="py-0">
                        <v-row justify="start">
                          <v-col
                            v-for="(task, i) in selectedTasks"
                            :key="i"
                            class="shrink"
                          >
                            <v-chip
                              :disabled="loading"
                              close
                              @click:close="onCloseTask(task)"
                            >
                              {{ task.name }}
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-btn color="primary" @click="onShowDialogTaskName">
                        เพิ่ม
                      </v-btn>

                      <v-dialog v-model="dialogTaskName" max-width="900px">
                        <v-card>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12">
                                <app-table
                                  :mode="mode"
                                  :headers="taskHeaders"
                                  :server-items="tasks"
                                  :client-items="tasks"
                                  :server-items-length="tasks.length"
                                  v-model="editingReport.tasks"
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="onCloseDialogTaskName"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="updateSelectedTasks"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <app-input
                        name="detail"
                        type="html-editor"
                        :label="$t('fields.detail')"
                        :view="view"
                        v-model="editingReport.detail"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="onCloseReport"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="onSaveReport"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </app-menu-form-top>
      </div>
    </template>
  </app-menu-form>
</template>

<script>
import AppMenuForm from '@components/AppMenuForm.vue'
import AppMenuFormTop from '@components/view/AppMenuFormTop.vue'
import AppInput from '@components/AppInput.vue'
import AppTable from '@components/AppTable.vue'
import { defaultTableParams } from '@utils/app-table'

export default {
  name: 'AppReport',
  components: {
    AppMenuForm,
    AppMenuFormTop,
    AppInput,
    AppTable
  },
  props: {
    mode: {
      type: String
    },
    view: {
      type: Boolean
    },
    reportType: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogTaskName: false,
      editingReport: {
        tasks: []
      },
      reports: [],
      tasks: [],
      status: ''
    }
  },
  computed: {
    eventHandlers() {
      return {
        server: this.getReports,
        edit: (item) => {
          this.editingReport = Object.assign({}, item)
          this.dialog = true
        },
        delete: this.onDeleteReport
      }
    },
    taskEventHandlers() {
      return {
        server: this.getTasks
      }
    },
    selectedTasks() {
      this.removeDuplicateTasks()
      return this.editingReport?.tasks ?? []
    },
    taskHeaders() {
      return [
        {
          text: this.$t('fields.task'),
          value: 'name',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.selected'),
          value: 'selected',
          check: true,
          hideFilter: true
        }
      ]
    },
    reportHeaders() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.name'),
          value: 'name',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.detail'),
          value: 'detail',
          sortable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.reportTaskName'),
          value: 'tasks',
          sortable: false,
          hideFilter: true,
          chips: {
            color: 'primary',
            value: 'name',
            router: this.taskRouter
          }
        },
        {
          text: this.$t('fields.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  methods: {
    async onShowDialogTaskName() {
      await this.getTasks()
      this.dialogTaskName = true
    },
    onCloseDialogTaskName() {
      this.dialogTaskName = false
    },
    removeDuplicateTasks() {
      const uniqueTasks = new Map()
      this.editingReport.tasks?.forEach((task) => {
        if (!uniqueTasks.has(task.id)) {
          uniqueTasks.set(task.id, task)
        }
      })
      this.editingReport.tasks = Array.from(uniqueTasks.values())
    },
    async getTasks() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 10
        })
        const { data } = await this.$api({
          method: 'get',
          url: `task/task-conclusion-report-v2?audit_id=${this.$route.params.id}&report_type=${this.reportType}`,
          params: params,
          hideSuccessAlert: true
        })
        this.tasks = data.results.map((d) => {
          return {
            id: d.task_id,
            name: d.task_name,
            selected: d.report_task
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    updateSelectedTasks() {
      this.dialogTaskName = false
    },
    onCloseReport() {
      this.editingReport = {
        tasks: []
      }
      this.dialog = false
    },
    async onSaveReport() {
      try {
        this.loading = true
        const data = {
          name: this.editingReport.name,
          type: this.reportType,
          task_ids: this.editingReport.tasks.map((t) => t.id)
        }

        if (this.editingReport.sequence) {
          data.sequence = this.editingReport.sequence
        }

        if (this.editingReport.detail) {
          data.detail = this.editingReport.detail
        }

        if (this.editingReport.id) {
          this.$api({
            method: 'put',
            url: `audit/audit-header/${this.$route.params.id}/report-tasks/${this.editingReport.id}/`,
            data
          }).then(() => {
            this.getReports()
          })
        } else {
          this.$api({
            method: 'post',
            url: `audit/audit-header/${this.$route.params.id}/report-tasks/`,
            data
          }).then(() => {
            this.getReports()
          })
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
        this.editingReport = {
          tasks: []
        }
        this.dialog = false
      }
    },
    async getReports() {
      this.loading = true
      try {
        let params = defaultTableParams({
          page: 1,
          itemsPerPage: 100
        })
        const { data } = await this.$api({
          method: 'get',
          url: `audit/audit-header/${this.$route.params.id}/report-tasks/?report_type=${this.reportType}`,
          params: params,
          hideSuccessAlert: true
        })
        if (data.count > 0) {
          const reportMap = new Map()

          data.results.forEach((result) => {
            const report = result.report_id
            const task = result.task_id

            if (reportMap.has(report.id)) {
              const rem = reportMap.get(report.id)
              rem.tasks.push(task)
            } else {
              reportMap.set(report.id, {
                id: report.id,
                sequence: report.sequence,
                name: report.name,
                detail: report.detail,
                tasks: [task]
              })
            }
          })

          this.reports = Array.from(reportMap.values()).map((report) => {
            return {
              ...report,
              tasks: report.tasks
                .filter((task) => task !== null)
                .map((task) => ({
                  id: task?.id || '',
                  name: task?.name || '',
                  selected: true
                }))
            }
          })
        } else {
          this.reports = []
        }

        const { data: data2 } = await this.$api({
          method: 'get',
          url: `task/task-conclusion-report-v2/get_task_report_status?audit_id=${this.$route.params.id}&report_type=${this.reportType}`,
          hideSuccessAlert: true
        })
        this.status = data2.status
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onCloseTask(task) {
      this.editingReport.tasks = this.editingReport.tasks.filter(
        (t) => t.id !== task.id
      )
    },
    onDeleteReport(items) {
      if (items.length === 0) {
        return
      }
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-header/${this.$route.params.id}/report-tasks/${items?.[0]?.id}/`
        }).then(() => {
          this.getReports()
        })
      })
      this.loading = false
    },
    taskRouter(task) {
      return {
        name: 'taskEdit',
        params: { id: task.id }
      }
    }
  }
}
</script>
