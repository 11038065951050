<template>
  <v-data-table
    ref="table"
    multi-sort
    :show-select="false"
    :headers="headers"
    :items="visibleItems"
    :items-per-page="-1"
    :item-key="itemKey"
    :loading="loading"
    :search="search"
    :group-by="groupBy"
    :group-desc="[]"
    :custom-group="customGroup"
    :custom-sort="customSort"
    :options="options"
    v-bind="binds"
    :show-expand="isExpand || isDescription"
    @item-expanded="itemExpanded"
    :single-expand="singleExpand"
    :hide-default-footer="hideDefaultFooter"
    @update:options="updateOptions"
    hide-default-header
  >
    <template v-slot:header>
      <thead class="v-data-table-header">
        <tr>
          <th class="col-expander"></th>
          <th
            v-for="(header, index) in headers"
            :key="index"
            :colspan="index === 0 ? 3 : 1"
          >
            <span class="d-flex align-center justify-space-between">
              <template v-if="header.html">
                <span v-html="header.html"></span>
              </template>
              <template v-else>
                <span>{{ header.text }}</span>
              </template>
              <span v-if="!header.hideFilter">
                <v-menu offset-y :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on">
                      <v-icon small :color="colorIconFilter(header.value)">
                        mdi-filter
                      </v-icon>
                    </v-btn>
                  </template>
                  <div style="background-color: white; width: 280px">
                    <template v-if="header.filterType === 'boolean'">
                      <v-checkbox
                        v-model="textFilter[header.value]"
                        indeterminate
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <v-text-field
                        class="pa-4"
                        type="text"
                        label="Enter the search term"
                        v-model="textFilter[header.value]"
                        :autofocus="true"
                      ></v-text-field>
                    </template>
                    <v-btn
                      @click="clearFilter(header.value)"
                      small
                      text
                      color="primary"
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                  </div>
                </v-menu>
              </span>
            </span>
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:body="{ items }">
      <transition-group name="fade" tag="tbody">
        <template v-for="item in items">
          <tr :key="item.key">
            <td class="col-expander">
              <v-btn
                icon
                small
                v-if="hasExpandableItems(item, 1)"
                @click="toggleExpanded(item.key)"
                ><v-icon small>{{
                  isExpanded(item.key) ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon></v-btn
              >
            </td>
            <app-report-table-cell
              v-for="(header, index) in headers"
              :colspan="index === 0 ? 3 : 1"
              :header="header"
              :item="item"
              :key="`${item.key}_${header.value}`"
              :bold="item.bold || false"
            />
          </tr>
          <template v-for="lv2Item in getLv2Items(item)">
            <tr :key="lv2Item.key" v-show="isExpanded(item.key)">
              <td class="col-expander"></td>
              <td class="col-expander">
                <v-btn
                  icon
                  small
                  v-if="hasExpandableItems(lv2Item, 2)"
                  @click="toggleExpanded(lv2Item.key)"
                  ><v-icon small>{{
                    isExpanded(lv2Item.key)
                      ? 'mdi-chevron-up'
                      : 'mdi-chevron-down'
                  }}</v-icon></v-btn
                >
              </td>
              <app-report-table-cell
                v-for="(header, index) in headers"
                :colspan="index === 0 ? 2 : 1"
                :header="header"
                :item="lv2Item"
                :key="`${lv2Item.key}_${header.value}`"
              />
            </tr>
            <tr
              v-for="lv3Item in getLv3Items(lv2Item)"
              :key="lv3Item.key"
              v-show="isExpanded(item.key) && isExpanded(lv2Item.key)"
            >
              <td class="col-expander"></td>
              <td class="col-expander"></td>
              <td class="col-expander"></td>
              <app-report-table-cell
                v-for="header in headers"
                :header="header"
                :item="lv3Item"
                :key="`${lv3Item.key}_${header.value}`"
              />
            </tr>
          </template>
        </template>
      </transition-group>
    </template>

    <!-- @slot default -->
    <slot />
  </v-data-table>
</template>

<script setup>
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { computed, ref } from 'vue'
import AppReportTableCell from '@components/AppReportTableCell.vue'

const props = defineProps({
  app: {
    type: String,
    required: true
  },
  model: {
    type: String,
    required: true
  },
  value: {
    type: Array,
    default: () => []
  },
  headers: {
    type: Array,
    required: true
  },
  clientItems: {
    type: Array,
    required: true
  },
  itemKey: {
    type: String,
    default: 'id'
  },
  loading: {
    type: Boolean,
    default: false
  },
  hideDefaultFooter: {
    type: Boolean,
    default: false
  },
  groupBy: {
    type: String
  },
  customGroup: {
    type: Function
  },
  customSort: {
    type: Function
  },
  subItemKey: {
    type: String
  },
  isExpand: {
    type: Boolean,
    default: false
  },
  isDescription: {
    type: Boolean,
    default: false
  },
  prepareSubHeaders: {
    type: Function
  },
  prepareSubItems: {
    type: Function
  },
  binds: {
    type: Object,
    default: () => ({})
  },
  search: {
    type: String,
    default: ''
  },
  singleExpand: {
    type: Boolean,
    default: false
  },
  options: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['link', 'item-expanded', 'update:options'])

const textFilter = ref({})

const visibleItems = computed(() => {
  let items = props.clientItems
  _.forEach(textFilter.value, (value, key) => {
    if (value !== '') {
      items = items.filter((item) => {
        if (!item[key]) {
          return false
        }
        return item[key].toString().toLowerCase().includes(value.toLowerCase())
      })
    }
  })
  return items
})

const itemExpanded = ({ item, value }) => {
  emit('item-expanded', item, value)
}

const clearFilter = (header) => {
  textFilter.value[header] = ''
  delete textFilter.value[header]
}

const colorIconFilter = (header) => {
  return textFilter.value[header] ? 'primary' : ''
}

const updateOptions = (options) => {
  emit('update:options', options)
}

// const getItem = (items, key) => {
//   return items.find((item) => item[props.itemKey] === key)
// }
const getLv2Items = (lv1Item) => {
  if (!lv1Item) {
    return []
  }
  return lv1Item['master_chart_of_account_details']
}
const getLv3Items = (lv2Item) => {
  if (!lv2Item) {
    return []
  }
  return lv2Item['chart_of_account_details']
}

// eslint-disable-next-line no-unused-vars
const { permissions } = mapGetters(['permissions'])

const expanded = ref([])
const toggleExpanded = (key) => {
  const indexExpanded = expanded.value.findIndex((i) => i === key)
  if (indexExpanded >= 0) {
    expanded.value.splice(indexExpanded, 1)
  } else {
    expanded.value.push(key)
  }
}
const isExpanded = (key) => {
  const indexExpanded = expanded.value.findIndex((i) => i === key)
  return indexExpanded >= 0
}
const hasExpandableItems = (item, level) => {
  if (!item) {
    return false
  }
  if (level === 1) {
    return !_.isEmpty(item['master_chart_of_account_details'])
  }
  if (level === 2) {
    return !_.isEmpty(item['chart_of_account_details'])
  }
  return false
}
</script>

<style scoped lang="scss">
.v-data-table > .v-data-table__wrapper > table > thead > tr > th.col-expander {
  width: 28px;
  padding-left: 0;
  padding-right: 0;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.col-expander {
  width: 28px;
  padding-left: 0;
  padding-right: 0;
}
.fade-enter-active {
  transition: opacity 0.25s;
}

.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
