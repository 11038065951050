import { getFile } from '@utils/function'

const downloadFile = async (url, fileName, fileType) => {
  const fileObject = await getFile(url, fileName, fileType)
  let href, link
  try {
    href = window.URL.createObjectURL(
      new Blob([fileObject], {
        type: fileType,
        encoding: 'UTF-8'
      })
    )
    link = document.createElement('a')
    link.href = href
    link.download = fileName
    link.setAttribute('target', '_blank')
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    console.error(e)
  } finally {
    if (link) {
      document.body.removeChild(link)
    }
    if (href) {
      window.URL.revokeObjectURL(href)
    }
  }
}

export default downloadFile
