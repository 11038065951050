var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-form-view',{attrs:{"multipart":"","app":"contact","model":"personaldocument","api-url":"contact/personal-doc/","title":_vm.$t('menu.otherDocument'),"state":_vm.formData.state,"stateItems":_vm.stateItems,"approveItems":_vm.approveFields,"prepare-data":_vm.prepareData},on:{"input":_vm.loadDocuments},scopedSlots:_vm._u([{key:"default",fn:function({ view, mode }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('app-input',{attrs:{"name":"name","label":_vm.$t('fields.documentName'),"view":view},model:{value:(_vm.formData.document_name),callback:function ($$v) {_vm.$set(_vm.formData, "document_name", $$v)},expression:"formData.document_name"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"name":"approveContact","type":"switch","label":_vm.$t('fields.approve')},model:{value:(_vm.formData.approve_document_name),callback:function ($$v) {_vm.$set(_vm.formData, "approve_document_name", $$v)},expression:"formData.approve_document_name"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","md":"6"}},[_c('app-input',{attrs:{"name":"type","type":"select","rules":"required","label":_vm.$t('fields.type'),"items":[
                { label: _vm.$t('label.individual'), value: 'individual' },
                { label: _vm.$t('label.company'), value: 'company' }
              ],"view":view},scopedSlots:_vm._u([{key:"view-value",fn:function({ value }){return [_vm._v(" "+_vm._s(_vm.$t(`label.${value}`))+" ")]}}],null,true),model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"name":"approveType","type":"switch","label":_vm.$t('fields.approve')},model:{value:(_vm.formData.approve_type),callback:function ($$v) {_vm.$set(_vm.formData, "approve_type", $$v)},expression:"formData.approve_type"}}):_vm._e()],1),_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","md":"6"}},[_c('app-input',{attrs:{"name":"contact","type":"chips-server","rules":"required","label":_vm.$t('fields.contact'),"view":view,"disabled":!Boolean(_vm.formData.type),"binds":{
                apiUrl: `contact/contact/?active=true&contact_type=${_vm.formData.type}`,
                chip: {
                  color: 'secondary',
                  icon: 'mdi-account-circle-outline',
                  value: 'name',
                  router: _vm.contactRouter
                }
              },"filterObject":_vm.filterObject},model:{value:(_vm.formData.contact_ids),callback:function ($$v) {_vm.$set(_vm.formData, "contact_ids", $$v)},expression:"formData.contact_ids"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"name":"approveContact","type":"switch","label":_vm.$t('fields.approve')},model:{value:(_vm.formData.approve_contact_ids),callback:function ($$v) {_vm.$set(_vm.formData, "approve_contact_ids", $$v)},expression:"formData.approve_contact_ids"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","md":"6"}},[_c('app-input',{attrs:{"name":"documentTemplate","type":"select-server","rules":"required","label":_vm.$t('fields.documentTemplate'),"view":view,"disabled":!Boolean(_vm.formData.type),"binds":{
                apiUrl: `doc-template/document/?active=true&contact_type=${_vm.formData.type}&type__in=do,as,li,eq,re,ex,hu,la,ge`
              },"events":{
                change: _vm.onChangeDocumentTemplate
              }},model:{value:(_vm.formData.document_template_id),callback:function ($$v) {_vm.$set(_vm.formData, "document_template_id", $$v)},expression:"formData.document_template_id"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"name":"approveDocumentTemplate","type":"switch","label":_vm.$t('fields.approve')},model:{value:(_vm.formData.approve_document_template_id),callback:function ($$v) {_vm.$set(_vm.formData, "approve_document_template_id", $$v)},expression:"formData.approve_document_template_id"}}):_vm._e()],1),(_vm.formData.document_template_id)?_vm._l((_vm.documentTemplateFields),function(field){return _c('v-col',{key:field.field,staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","md":"6"}},[_c('app-input',{attrs:{"rules":{
                  required: true,
                  numeric: field.field_type === 'number'
                },"name":field.field,"type":field.field_type,"label":field.name,"items":_vm.fieldSelectionItems(field),"view":view},model:{value:(_vm.formData.fields[field.field]),callback:function ($$v) {_vm.$set(_vm.formData.fields, field.field, $$v)},expression:"formData.fields[field.field]"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"type":"switch","name":`approve-${field.name}`,"label":_vm.$t('fields.approve')},model:{value:(_vm.formData[`approve_${field.field}`]),callback:function ($$v) {_vm.$set(_vm.formData, `approve_${field.field}`, $$v)},expression:"formData[`approve_${field.field}`]"}}):_vm._e()],1)}):_vm._e()],2),_c('v-row',[_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","md":"6"}},[_c('app-input',{attrs:{"name":"customer_name","type":"select-server","rules":"required","label":_vm.$t('fields.customerCompany'),"view":view,"binds":{
                apiUrl: 'customer-company/customer_company/?active=true'
              }},model:{value:(_vm.formData.customer_company_id),callback:function ($$v) {_vm.$set(_vm.formData, "customer_company_id", $$v)},expression:"formData.customer_company_id"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"name":"approveCustomerCompany","type":"switch","label":_vm.$t('fields.approve')},model:{value:(_vm.formData.approve_customer_company),callback:function ($$v) {_vm.$set(_vm.formData, "approve_customer_company", $$v)},expression:"formData.approve_customer_company"}}):_vm._e()],1)],1),(!view)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('app-input',{attrs:{"name":"documents","type":"file","rules":"required","label":_vm.$t('fields.document'),"view":view,"binds":{ multiple: true, chips: true }},model:{value:(_vm.formData.documents),callback:function ($$v) {_vm.$set(_vm.formData, "documents", $$v)},expression:"formData.documents"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('app-input',{attrs:{"name":"note","type":"textarea","label":_vm.$t('fields.note'),"view":view},model:{value:(_vm.formData.note),callback:function ($$v) {_vm.$set(_vm.formData, "note", $$v)},expression:"formData.note"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('app-input',{attrs:{"name":"period","type":"select","label":_vm.$t('fields.period'),"view":view,"disabled":true,"items":[
                {
                  label: 'Monthly',
                  value: 'monthly'
                },
                {
                  label: 'Quarterly',
                  value: 'quarterly'
                },
                {
                  label: 'Yearly',
                  value: 'yearly'
                }
              ]},model:{value:(_vm.formData.period),callback:function ($$v) {_vm.$set(_vm.formData, "period", $$v)},expression:"formData.period"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('app-input',{attrs:{"name":"active","type":"switch","label":_vm.$t('fields.active'),"view":view},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('app-preview-file',{attrs:{"file-ready":_vm.documentReady || mode === 'create',"files":_vm.formData.documents || []}})],1)],1),_vm._t("default",null,{"view":view})]}}],null,true),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})
}
var staticRenderFns = []

export { render, staticRenderFns }