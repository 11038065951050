<template>
  <td :colspan="colspan">
    <!-- link -->
    <v-icon
      v-if="header.link"
      color="primary"
      :key="`link-${header.value}`"
      @click="onLink(header, item)"
    >
      mdi-link
    </v-icon>

    <!-- check -->
    <v-icon
      v-else-if="header.check"
      :color="getValue(header.value, item) ? 'green' : 'grey lighten-2'"
      :key="`check-${header.value}`"
    >
      mdi-check
    </v-icon>

    <!-- chip -->
    <template v-else-if="header.chips">
      <v-chip
        v-for="value in item[header.value]"
        link
        :key="value.id"
        :color="header.chips.color || 'primary'"
        :to="header.chips.router ? header.chips.router(value) : null"
      >
        <v-icon v-if="header.chips.icon" left>{{ header.chips.icon }}</v-icon>
        {{
          header.chips.subValue
            ? value[header.chips.value || 'name'][header.chips.subValue]
            : value[header.chips.value || 'name']
        }}
      </v-chip>
    </template>

    <!-- Account Format -->
    <template v-else-if="header.accountFormat">
      {{ accountFormattedValue(getValue(header.value, item)) }}
    </template>

    <!-- DateTime Format -->
    <template v-else-if="header.dateTimeFormat">
      {{ dateTimeFormattedValue(getValue(header.value, item)) }}
    </template>

    <!-- Hours Format -->
    <template v-else-if="header.hourFormat">
      {{ hoursFormattedValue(getValue(header.value, item)) }}
    </template>

    <!-- Percent Format -->
    <template v-else-if="header.percentFormat">
      {{ percentFormattedValue(getValue(header.value, item)) }}
    </template>

    <!-- default -->
    <template v-else>
      <span :class="textClass">{{ getValue(header.value, item) }}</span>
    </template>
  </td>
</template>

<script setup>
import { accounting, hoursMinutes } from '@utils/number-format'
import { format, parse } from 'date-fns'
import { computed } from 'vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  header: {
    type: Object,
    required: true
  },
  bold: {
    type: Boolean,
    default: false
  },
  colspan: {
    type: Number,
    default: 1
  }
})

const textClass = computed(() => {
  return props.bold ? 'font-weight-bold' : ''
})

const emit = defineEmits(['link'])
const onLink = (header, item) => {
  emit('link', header, item)
}
const stateFormat = (state) => {
  return state
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const getValue = (keys, item) => {
  const result = keys.split('.').reduce((acc, key) => acc?.[key], item) ?? ''
  return keys === 'state' ? stateFormat(result) : result
}

const accountFormattedValue = (item) => {
  return accounting(item)
}

const dateTimeFormattedValue = (item) => {
  if (!item) return ''
  try {
    const date = parse(item, "yyyy-MM-dd'T'HH:mm:ssxxx", new Date())
    return format(date, 'yyyy-MM-dd HH:mm')
  } catch (error) {
    console.error('Error on parse date', error)
  }
  return ''
}

const hoursFormattedValue = (item) => {
  return hoursMinutes(item)
}

const percentFormattedValue = (item) => {
  if (item == null || item === '') return ''
  return `${accounting(item)}%`
}
</script>
