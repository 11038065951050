import Swal from 'sweetalert2'

const swalBtnClass = 'v-btn v-btn--has-bg theme--light v-size--default mx-4'
const swal = Swal.mixin({
  target: '#app',
  customClass: {
    confirmButton: `${swalBtnClass} primary`,
    cancelButton: `${swalBtnClass} error`
  },
  buttonsStyling: false
})

export default swal
