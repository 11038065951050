<template>
  <app-form-view
    app="audit"
    model="task"
    api-url="/task/task/"
    :title="$t('menu.section')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
    @input="formLoaded"
    :is-closed="formData.audit_id?.is_closed"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.sectionName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="taskTemplate"
            rules="required"
            type="select-server"
            :label="$t('fields.taskTemplate')"
            :view="view"
            :binds="{
              apiUrl: 'task/task-template-header/?active=true'
            }"
            v-model="formData.task_template_header_id"
            ref="task_template_header"
            :events="{
              change: onTaskTemplateChange
            }"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveTask"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_task"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="contactID"
            type="select-server"
            :label="$t('fields.contactName')"
            :view="view"
            :disabled="true"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.contact_id"
            ref="contact"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveContactID"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_contact_id"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="parentTask"
            type="select-server"
            :label="$t('fields.parentTask')"
            :view="view"
            :binds="{
              apiUrl: parentTaskUrl
            }"
            v-model="formData.parent_task_id"
            ref="parent_task"
            :disabled="isParentTaskDisabled"
          />
          <app-input
            v-if="isSentToApprove"
            name="approvePatentTaskId"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_parent_task_id"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="sequence"
            rules="required"
            type="number"
            :view="view"
            :label="$t('fields.seq')"
            v-model="formData.sequence"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveSequence"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_sequence"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reference"
            :isUpperCase="true"
            :view="view"
            :label="$t('fields.reference')"
            v-model="formData.reference"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveReference"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_reference"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditYear"
            type="year-selection"
            :label="$t('fields.auditYear')"
            :view="view"
            :disabled="true"
            v-model="formData.audit_year"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditYear"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_year"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="auditPeriod"
            type="select"
            :label="$t('fields.auditPeriod')"
            :view="view"
            :disabled="true"
            v-model="formData.audit_period"
            :items="[
              {
                label: 'Q1',
                value: 'q1'
              },
              {
                label: 'Q2',
                value: 'q2'
              },
              {
                label: 'Q3',
                value: 'q3'
              },
              {
                label: 'Q4',
                value: 'q4'
              },
              { label: 'Year End', value: 'year_end' },
              {
                label: 'Customs',
                value: 'customs'
              }
            ]"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAuditPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_audit_period"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="deadline"
            type="datepicker"
            :label="$t('fields.deadline')"
            :view="view"
            v-model="formData.deadline"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDeadline"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_deadline"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriod"
            type="datepicker"
            :label="$t('fields.accountingPeriod')"
            :view="view"
            :disabled="true"
            v-model="formData.accounting_period"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriod"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="assignDate"
            type="datepicker"
            :label="$t('fields.assignDate')"
            :view="view"
            v-model="formData.assign_date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAssignDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_assign_date"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="assignTo"
            type="select-server"
            :label="$t('fields.assignTo')"
            :view="view"
            :binds="{
              apiUrl: `contact/contact/?active=true&audit_header_id=${auditId}`
            }"
            v-model="formData.assign_to"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAssignTo"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_assign_to"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="preparedDate"
            type="datepicker"
            :label="$t('fields.prepareDate')"
            :view="view"
            :disabled="true"
            v-model="formData.prepare_date"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="preparedBy"
            type="select-server"
            :label="$t('fields.preparedBy')"
            :view="view"
            :disabled="true"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.prepare_by"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewDate"
            type="datepicker"
            :label="$t('fields.reviewDate')"
            :view="view"
            :disabled="true"
            v-model="formData.review_date"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="reviewedBy"
            type="select-server"
            :label="$t('fields.reviewedBy')"
            :view="view"
            :disabled="true"
            :binds="{
              apiUrl: 'contact/contact/?active=true'
            }"
            v-model="formData.review_by"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="taskType"
            type="select"
            rules="required"
            :label="$t('fields.taskType')"
            :view="view"
            v-model="formData.task_type"
            :items="[
              {
                label: $t('label.debitAndCreditSum'),
                value: 'DCS'
              },
              {
                label: $t('label.debitAndCreditDetail'),
                value: 'DCD'
              },
              {
                label: $t('label.subsidiaryAccount'),
                value: 'SA'
              },
              { label: $t('label.stockDetailOutstanding'), value: 'STO' },
              { label: $t('label.combinedInOut'), value: 'CIO' },
              { label: $t('label.stockValueOutstanding'), value: 'SVO' }
            ]"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveTaskType"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_task_type"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodBeginning"
            type="datepicker"
            :label="$t('fields.accountingPeriodBeginning')"
            :view="view"
            :disabled="true"
            v-model="formData.accounting_period_beginning"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodBeginning"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_beginning"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="accountingPeriodEnding"
            rules="required"
            type="datepicker"
            :label="$t('fields.accountingPeriodEnding')"
            :view="view"
            :disabled="true"
            v-model="formData.accounting_period_ending"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveAccountingPeriodEnding"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_accounting_period_ending"
          />
        </v-col>
      </v-row>

      <v-expansion-panels multiple>
        <!-- General Panel -->
        <app-expansion-panel :headerText="$t('menu.general')">
          <v-expansion-panels multiple>
            <!-- Description Panel -->
            <app-expansion-panel :headerText="$t('menu.description')">
              <app-task-description
                :description="formData.description"
                :view="view"
              />
            </app-expansion-panel>
            <!-- Objective Panel -->
            <app-expansion-panel
              v-if="formData?.audit_id != null"
              :headerText="$t('menu.taskObjective')"
            >
              <app-task-objective :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Risk Profile Panel -->
            <app-expansion-panel :headerText="$t('menu.riskProfile')">
              <app-task-risk-profile :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Activity Profile Panel -->
            <app-expansion-panel :headerText="$t('menu.activityProfile')">
              <app-task-activity-profile :mode="mode" :view="view" />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
        <!-- Task Operation Panel -->
        <app-expansion-panel :headerText="$t('menu.taskOperation')">
          <v-expansion-panels multiple>
            <!-- Sub Task Panel -->
            <app-expansion-panel :headerText="$t('menu.subTask')">
              <app-sub-task :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Required Document Panel -->
            <app-expansion-panel :headerText="$t('menu.requiredDocument')">
              <app-required-document
                :mode="mode"
                :view="view"
                :contactId="formData.contact_id"
              />
            </app-expansion-panel>
            <!-- Debit Sum Panel -->
            <app-expansion-panel
              v-if="isShowDebitSum"
              :headerText="$t('menu.debitSum')"
            >
              <app-debit-sum
                :mode="mode"
                :view="view"
                :contactId="formData.contact_id"
              />
            </app-expansion-panel>
            <!-- Debit Detail Panel -->
            <app-expansion-panel
              v-if="isShowDebitDetail"
              :headerText="$t('menu.debitDetail')"
            >
              <app-debit-detail
                :mode="mode"
                :view="view"
                :contactId="formData.contact_id"
              />
            </app-expansion-panel>
            <!-- Questionnaire Panel -->
            <app-expansion-panel :headerText="$t('menu.questionnaire')">
              <app-questionnaire :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Conclusion Panel -->
            <app-expansion-panel :headerText="$t('menu.conclusion')">
              <app-conclusion :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Property and Agreement Panel -->
            <app-expansion-panel :headerText="$t('menu.propertyAndAgreement')">
              <app-property-agreement
                :mode="mode"
                :view="view"
                :contactId="formData.contact_id"
              />
            </app-expansion-panel>
            <!-- Subsidiary Account Panel -->
            <app-expansion-panel
              v-if="isShowSubsidiary"
              :headerText="$t('menu.subsidiaryAccount')"
            >
              <app-subsidiary-account
                :mode="mode"
                :view="view"
                :formData="formData"
              />
            </app-expansion-panel>
            <!-- Stock Detail Outstanding Panel -->
            <app-expansion-panel
              v-if="isShowStockDetail"
              :headerText="$t('menu.stockDetailOutstanding')"
            >
              <app-stock-detail-outstanding
                :mode="mode"
                :view="view"
                :formData="formData"
              />
            </app-expansion-panel>
            <!-- combined in and out Panel -->
            <app-expansion-panel
              v-if="isShowCombinedInOut"
              :headerText="$t('menu.combinedInOut')"
            >
              <app-combined-in-out
                :mode="mode"
                :view="view"
                :formData="formData"
              />
            </app-expansion-panel>
            <!-- Stock Value Outstanding Panel -->
            <app-expansion-panel
              v-if="isShowStockValue"
              :headerText="$t('menu.stockValueOutstanding')"
            >
              <app-stock-value-outstanding
                :mode="mode"
                :view="view"
                :formData="formData"
              />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
        <!-- Adjustment Panel -->
        <app-expansion-panel :headerText="$t('menu.adjustment')">
          <v-expansion-panels multiple>
            <!-- Adjustment Panel -->
            <app-expansion-panel
              v-if="formData?.audit_id != null"
              :headerText="$t('menu.adjustment')"
            >
              <app-adjustment :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Sum. Adjustment Panel -->
            <app-expansion-panel
              v-if="formData?.audit_id != null"
              :headerText="$t('menu.sumAdjustment')"
            >
              <app-sum-adjustment :mode="mode" :view="view" />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
        <!-- Related CoA Panel -->
        <app-expansion-panel :headerText="$t('menu.relatedCOA')">
          <v-expansion-panels multiple>
            <!-- Related CoA Panel -->
            <app-expansion-panel
              v-if="formData?.audit_id != null"
              :headerText="$t('menu.relatedCOA')"
            >
              <app-related-coa :mode="mode" :view="view" />
            </app-expansion-panel>
            <!-- Sum. Related CoA Panel -->
            <app-expansion-panel
              v-if="formData?.audit_id != null"
              :headerText="$t('menu.sumRelatedCOA')"
            >
              <app-sum-related-coa :mode="mode" :view="view" />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
        <!-- Timesheet Panel -->
        <app-expansion-panel :headerText="$t('menu.timesheet')">
          <v-expansion-panels multiple>
            <!-- Standard Manday Panel -->
            <app-expansion-panel :headerText="$t('menu.standardManDay')">
              <app-standard-manday :mode="mode" :view="view" />
            </app-expansion-panel>

            <!-- Timesheet Panel -->
            <app-expansion-panel :headerText="$t('menu.timesheet')">
              <app-timesheet
                :mode="mode"
                :view="view"
                :memberTimesheetItems="memberTimesheetItems"
              />
            </app-expansion-panel>
            <!-- timesheet by position Panel -->
            <!-- <app-timesheet-position /> -->
            <app-expansion-panel :headerText="$t('menu.timesheetByPosition')">
              <app-timesheet-position />
            </app-expansion-panel>
            <!-- timesheet by member Panel -->
            <app-expansion-panel :headerText="$t('menu.timesheetByMember')">
              <app-timesheet-member />
            </app-expansion-panel>
          </v-expansion-panels>
        </app-expansion-panel>
      </v-expansion-panels>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'
import AppExpansionPanel from '@components/AppExpansionPanel.vue'
import AppTimesheetMember from '@components/view/AppTimesheetMember'
import AppTimesheetPosition from '@components/view/AppTimesheetPosition'
import AppTaskDescription from '@components/view/AppTaskDescription'
import AppTaskObjective from '@components/view/AppTaskObjective'
import AppTaskActivityProfile from '@components/view/AppTaskActivityProfile'
import AppTaskRiskProfile from '@components/view/AppTaskRiskProfile'
import AppSubTask from '@components/view/AppSubTask'
import AppRequiredDocument from '@components/view/AppRequiredDocument'
import AppDebitSum from '@components/view/AppDebitSum'
import AppDebitDetail from '@components/view/AppDebitDetail'
import AppQuestionnaire from '@components/view/AppQuestionnaire'
import AppConclusion from '@components/view/AppConclusion'
import AppPropertyAgreement from '@components/view/AppPropertyAgreement'
import AppSubsidiaryAccount from '@components/view/AppSubsidiaryAccount'
import AppStockDetailOutstanding from '@components/view/AppStockDetailOutstanding'
import AppCombinedInOut from '@components/view/AppCombinedInOut'
import AppStockValueOutstanding from '@components/view/AppStockValueOutstanding'
import AppAdjustment from '@components/view/AppAdjustment'
import AppSumAdjustment from '@components/view/AppSumAdjustment'
import AppRelatedCoa from '@components/view/AppRelatedCoa'
import AppSumRelatedCoa from '@components/view/AppSumRelatedCoa'
import AppStandardManday from '@components/view/AppStandardManday'
import AppTimesheet from '@components/view/AppTimesheet'
import { defaultTableParams } from '@utils/app-table'
import { accounting, hoursMinutes, percentFormat } from '@utils/number-format'
import { stripHtml } from '@utils/string-format'

export default {
  name: 'task',
  components: {
    AppInput,
    AppFormView,
    AppExpansionPanel,
    AppTimesheetMember,
    AppTaskDescription,
    AppTaskObjective,
    AppTaskActivityProfile,
    AppTaskRiskProfile,
    AppSubTask,
    AppRequiredDocument,
    AppDebitSum,
    AppDebitDetail,
    AppQuestionnaire,
    AppConclusion,
    AppPropertyAgreement,
    AppSubsidiaryAccount,
    AppStockDetailOutstanding,
    AppCombinedInOut,
    AppStockValueOutstanding,
    AppAdjustment,
    AppSumAdjustment,
    AppRelatedCoa,
    AppSumRelatedCoa,
    AppStandardManday,
    AppTimesheet,
    AppTimesheetPosition
  },
  data() {
    return {
      user_id: null,
      formData: {
        state: 'draft'
      },
      tab: null,
      loading: false,
      approveItems: [
        'approve_name',
        'approve_task',
        'approve_contact_id',
        'approve_parent_task_id',
        'approve_sequence',
        'approve_audit_year',
        'approve_audit_period',
        'approve_deadline',
        'approve_accounting_period',
        'approve_assign_date',
        'approve_assign_to',
        'approve_task_type'
      ],
      apiMethod: 'post',
      editedItem: {
        document_template_id: null,
        questionAnswer: [],
        text_answer: null,
        answerScore: 0,
        questionAnswerDrop: null,
        id: null
      },
      parentTaskUrl: null,

      dialogNewTask: false,
      newTaskTemplate: null,
      previousPage: null,
      memberTimesheetItems: [],
      timesheetByPositions: [],
      timesheetByPositionsCount: 0,
      timesheetByPositionSumStandardManDay: 0,
      timesheetByPositionSumAssignedManDay: 0,
      timesheetByPositionSumUnassigned: 0,
      timesheetByPositionSumAssignedProgress: 0,
      timesheetByPositionSumFinishedAssignedManDay: 0,
      timesheetByPositionSumActualManDay: 0,
      timesheetByPositionSumVariance: 0,
      timesheetByPositionSumTaskPerformance: 0,
      timesheetByPositionSumWorkProgress: 0,

      selected: [],

      answerScore: 0,

      currentTaskTemplateHeader: null,
      auditId: null
    }
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'taskEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'taskEdit',
          text: value,
          to: {
            name: 'taskEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return (
        this.formData.state === 'sent_to_approve' &&
        !this.formData.audit_id.is_closed
      )
    },
    isParentTaskDisabled() {
      return !!this.$route.query.parent_task_id
    },
    timesheetByPositionHeaders() {
      return [
        {
          text: this.$t('fields.position'),
          value: 'position_name',
          hideFilter: false,
          filterName: 'position__name'
        },
        {
          text: this.$t('fields.standardMD'),
          value: 'standard_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedMD'),
          value: 'expected_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.unassigned'),
          value: 'unassigned',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.assignedProgress'),
          value: 'assigned_progress',
          hideFilter: true,
          sortable: false,
          percentFormat: true
        },
        {
          text: this.$t('fields.finishedAssignedMD'),
          value: 'finished_assigned_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.actualManDay'),
          value: 'actual_man_day',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.variance'),
          value: 'variance',
          hideFilter: true,
          hourFormat: true
        },
        {
          text: this.$t('fields.taskPerformance'),
          value: 'task_performance',
          hideFilter: true,
          percentFormat: true
        },
        {
          text: this.$t('fields.workProgress'),
          value: 'work_progress',
          hideFilter: true,
          percentFormat: true
        }
      ]
    },
    isShowDebitSum() {
      return this.formData.task_type === 'DCS'
    },
    isShowDebitDetail() {
      return this.formData.task_type === 'DCD'
    },
    isShowCombinedInOut() {
      return this.formData.task_type === 'CIO'
    },
    isShowSubsidiary() {
      return this.formData.task_type === 'SA'
    },
    isShowStockDetail() {
      return this.formData.task_type === 'STO'
    },
    isShowStockValue() {
      return this.formData.task_type === 'SVO'
    },
    manDayHeaders() {
      return [
        {
          text: this.$t('fields.position'),
          value: 'position.name'
        },
        {
          text: this.$t('fields.manDay'),
          value: 'man_day',
          hourFormat: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  mounted() {
    if (this.$route.query.audit_plan_id) {
      this.getDataFromAuditPlanMaster()
      this.getMembers()
    }
    if (this.$route.query.task_template_header_id) {
      this.getDataFromTaskTemplate()
    }
    if (this.$route.query.task_type) {
      this.formData.task_type = this.$route.query.task_type
    }

    /* Force RefreshToken for Update User Info Before getUserLogin() */
    this.$store.dispatch('refreshToken').then(() => {
      this.getUserLogin()
      this.getParentTaskItem()
    })
  },
  created() {
    this.auditId = this.$route.query.audit_id || 0
    if (this.$route.name === 'taskCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'taskCreate'
      })
    }
  },
  methods: {
    accountingFormat: accounting,
    hoursMinutesFormat: hoursMinutes,
    percentFormat: percentFormat,
    stripHtml: stripHtml,
    formLoaded(value) {
      this.currentTaskTemplateHeader = value.task_template_header_id
    },
    groupLink(item) {
      return {
        name: 'groupLink',
        query: { group_link: item }
      }
    },
    getUserLogin() {
      const user_id = this.$store.getters.user.id
      this.$api({
        method: 'get',
        url: `contact/contact/?active=true&user_id=${user_id}`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        if (data.results.length) {
          this.user_id = data.results[0].id
        }
      })
    },
    getParentTaskItem() {
      let url = ''
      if (
        this.$route.query.audit_plan_id !== null &&
        this.$route.query.audit_plan_id !== undefined
      ) {
        url =
          'task/task/?active=true&audit_plan_id=' +
          this.$route.query.audit_plan_id
      }
      this.parentTaskUrl = url

      this.formData.parent_task_id = this.$route.query.parent_task_id
      if (url && this.$route.query.parent_task_id) {
        this.$api({
          method: 'get',
          url: `task/task/${this.$route.query.parent_task_id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.$set(this.formData, 'parent_task_id', data.detail)
          this.$refs.parent_task.setDefaultServerItems(data.detail)
        })
      }
    },
    onEdit(item) {
      this.editedItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogRequiredDocument = true
    },
    onDeleteItem(item) {
      this.onDelete(item)
    },
    onDelete(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `task/task-required-document/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.$refs.table.onServer()
        })
      })
    },
    getDataFromAuditPlanMaster() {
      const previous = this.$route.query.from
      if (['audit-plan-header', 'audit-header'].includes(previous)) {
        this.$api({
          method: 'get',
          url: `audit/audit-plan-header/${this.$route.query.audit_plan_id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.formData.contact_id = data.detail.contact_id
          this.formData.reference = data.detail.reference
          this.formData.audit_year = data.detail.audit_year
          this.formData.audit_period = data.detail.audit_period
          this.formData.deadline = data.detail.deadline
          this.formData.accounting_period = data.detail.accounting_period
          this.formData.accounting_period_beginning =
            this.$route.query.accounting_period_beginning
          this.formData.accounting_period_ending =
            this.$route.query.accounting_period_ending
          this.formData = Object.assign({}, this.formData)
          if (!this.$route.params.id) {
            this.$refs.contact.setDefaultServerItems(this.formData.contact_id)
          }
        })
      }
    },
    getDataFromTaskTemplate() {
      const task_template_header_id = this.$route.query.task_template_header_id
      this.$api({
        method: 'get',
        url: `task/task-template-header/${task_template_header_id}`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        const task_template_header = data.detail
        this.formData.task_template_header_id = task_template_header
        // not working and broke task template header
        // this.formData.audit_year =
        //   task_template_header.audit_pland_id.audit_year
        // this.formData.accounting_period_beginning =
        //   task_template_header.audit_pland_id.accounting_period_beginning
        // this.formData.accounting_period_ending =
        //   task_template_header.audit_pland_id.accounting_period_ending
        this.$refs.task_template_header.setDefaultServerItems(
          this.formData.task_template_header_id
        )
      })
    },
    prepareData(data) {
      this.previousPage = this.$route.query.from
      if (data.task_template_header_id && data.task_template_header_id.id) {
        data.task_template_header_id = data.task_template_header_id.id
      }
      if (data.contact_id && data.contact_id.id) {
        data.contact_id = data.contact_id.id
      }
      if (
        this.formData.assign_to !== undefined &&
        this.formData.assign_to !== null
      ) {
        data.assign_to =
          data.assign_to && data.assign_to.id ? data.assign_to.id : null
      }

      if (
        this.formData.parent_task_id !== undefined &&
        this.formData.parent_task_id !== null
      ) {
        data.parent_task_id =
          data.parent_task_id && data.parent_task_id.id
            ? data.parent_task_id.id
            : null
      }

      if (this.formData.state === 'draft') {
        data.prepare_by = null
        data.review_by = null
      } else if (
        this.formData.state === 'sent_to_approve' &&
        this.formData.prepare_by === null
      ) {
        data.prepare_by = this.user_id
        data.prepare_date = new Date().toISOString().slice(0, 10)
      } else if (
        this.formData.state === 'approved' &&
        this.formData.review_by === null
      ) {
        data.prepare_by =
          data.prepare_by && data.prepare_by.id ? data.prepare_by.id : null
        data.review_by = this.user_id
        data.review_date = new Date().toISOString().slice(0, 10)
      } else {
        data.prepare_by =
          data.prepare_by && data.prepare_by.id ? data.prepare_by.id : null
        data.review_by =
          data.review_by && data.review_by.id ? data.review_by.id : null
      }

      if (this.previousPage === 'audit-plan-header') {
        if (data.audit_plan_id !== undefined && data.audit_plan_id !== null) {
          data.audit_plan_id =
            data.audit_plan_id && data.audit_plan_id.id
              ? data.audit_plan_id.id
              : null
        }
        if (
          this.$route.query.audit_plan_id !== undefined &&
          data.audit_plan_id !== null
        ) {
          data.audit_plan_id = this.$route.query.audit_plan_id
        }
      } else if (this.previousPage === 'audit-header') {
        data.audit_id = this.$route.query.audit_id
        data.audit_plan_id = this.$route.query.audit_plan_id
      }

      if (this.$route.query.task_debit_summary_id) {
        data.task_debit_summary_id = this.$route.query.task_debit_summary_id
      }
      if (this.$route.query.task_debit_detail_id) {
        data.task_debit_detail_id = this.$route.query.task_debit_detail_id
      }
      if (this.$route.query.task_subsidiary_id) {
        data.task_subsidiary_id = this.$route.query.task_subsidiary_id
      }
      if (this.$route.query.task_stock_detail_id) {
        data.task_stock_detail_id = this.$route.query.task_stock_detail_id
      }
      if (this.$route.query.task_stock_value_id) {
        data.task_stock_value_id = this.$route.query.task_stock_value_id
      }
      if (this.$route.query.task_combined_in_out_id) {
        data.task_combined_in_out_id = this.$route.query.task_combined_in_out_id
      }

      return data
    },

    getMembers() {
      const auditParam = this.$route.query.audit_id
        ? `audit_header_id=${this.$route.query.audit_id}`
        : 'audit_header_id__isnull=true'
      this.$api({
        method: 'get',
        url: `audit/audit-plan-member/?audit_plan_header_id=${this.$route.query.audit_plan_id}&${auditParam}`,
        hideSuccessAlert: true
      }).then(({ data }) => {
        const dt = data.results.map((item) => ({
          value: item.id,
          label: item.contact_id.name
        }))
        this.memberTimesheetItems = dt
      })
    },
    async getTimesheetByPositions(options = null) {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === undefined
      ) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `audit/task/${this.$route.params.id}/timesheet-by-position/`,
          hideSuccessAlert: true,
          params: params
        })
        this.timesheetByPositions = data.results
        this.timesheetByPositionsCount = data.count

        this.timesheetByPositionSumStandardManDay = data.sum_standard_man_day
        this.timesheetByPositionSumAssignedManDay = data.sum_expected_man_day
        this.timesheetByPositionSumUnassigned = data.sum_unassigned
        this.timesheetByPositionSumAssignedProgress = data.sum_assigned_progress
        this.timesheetByPositionSumFinishedAssignedManDay =
          data.sum_finished_assigned_man_day
        this.timesheetByPositionSumActualManDay = data.sum_actual_man_day
        this.timesheetByPositionSumVariance = data.sum_variance
        this.timesheetByPositionSumTaskPerformance = data.sum_task_performance
        this.timesheetByPositionSumWorkProgress = data.sum_work_progress
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async getManDays(options = null) {
      this.loading = true
      try {
        let params = defaultTableParams(options)
        const { data } = await this.$api({
          method: 'get',
          url: `task/task/${this.$route.params.id}/standard-man-day/`,
          params: params,
          hideSuccessAlert: true
        })
        this.manDaysItemsCount = data.count
        this.manDaysItems = data.results
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },

    async onTaskTemplateChange(val) {
      const after = val
      const before = this.currentTaskTemplateHeader
      if (after !== null && after.id !== before?.id) {
        if (before != null) {
          const answer = await this.$alert('custom', {
            icon: 'warning',
            title: this.$t('Are you sure?'),
            text: this.$t(
              'This action will delete all your existing Standard man day.'
            ),
            showCancelButton: true
          })
          if (!answer.value) {
            this.formData.task_template_header_id = before
            this.$refs.task_template_header.setDefaultServerItems(
              this.formData.task_template_header_id
            )
            return
          }
        }
        if (this.$route.params.id) {
          this.$api({
            method: 'post',
            url: `task/task/${this.$route.params.id}/standard-man-day/copy_from_task_template/?task_template_id=${after.id}`
          }).then(() => {
            this.currentTaskTemplateHeader = val
            this.getManDays()
          })
        }
      }
    }
  }
}
</script>
